<template>
  <Card class="card app-card" :class="{ 'admin-mode': adminMode }" title="">
    <img src="../transparent_logo.png" />

    <template v-if="availableLanguages.length > 1">
      <div class="language-picker" v-if="settingLanguage">Switching language...</div>
      <div class="language-picker" v-else-if="myself">
        <div
          class="language-selection"
          v-for="lang in availableLanguages"
          :key="lang"
          :class="{ active: studyingLanguage === lang }"
          @click.prevent="onSelect(lang)">
          {{ lang }}
        </div>
      </div>
    </template>

    <div class="id-links">
      <span v-if="isStaff">
        <a href @click.prevent="onToggleAdminMode">
          {{ adminMode ? 'User Mode' : 'Admin Mode' }}
        </a>
      </span>
      <span class="who">{{ email }}</span>
      <span class="logout">
        <a href @click.prevent="$emit('logout')">
          <font-awesome-icon :icon="['fad', 'sign-out']" /> Log out
        </a>
      </span>
    </div>
  </Card>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  import { faSignOut } from '@fortawesome/pro-duotone-svg-icons/faSignOut';
  import { SET_LANGUAGE, TOGGLE_ADMIN_MODE } from '../constants';
  import Card from './Card.vue';

  library.add(faSignOut);

  export default {
    props: ['myself'],
    components: { Card, FontAwesomeIcon },
    computed: {
      email() {
        return this.$store.state.email;
      },
      studyingLanguage() {
        return this.$store.state.studyingLanguage;
      },
      availableLanguages() {
        return this.$store.state.availableLanguages;
      },
      settingLanguage() {
        return this.$store.state.settingLanguage;
      },
      isStaff() {
        return this.$store.state.isStaff;
      },
      adminMode() {
        return this.$store.state.adminMode;
      },
    },
    methods: {
      onToggleAdminMode() {
        this.$store.dispatch(TOGGLE_ADMIN_MODE);
      },
      onSelect(language) {
        if (this.studyingLanguage !== language) {
          this.$store.dispatch(SET_LANGUAGE, { language }).then(() => {
            this.$gtag.event('switch_language');
            this.$mixpanel.track('SwitchLanguage');
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-card {
    padding: 0.5rem;
    &:not(.admin-mode) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .language-selection {
        margin: auto 0;
        padding: 0.5rem 1rem;
      }
      .id-links {
        margin: auto 0;
        font-size: 1rem;
      }
    }
  }
  .language-picker {
    margin-top: 0.5rem;
    display: flex;
  }
  .language-selection {
    padding: 1rem 2rem;
    background: rgba(white, 0.1);
    color: rgba(white, 0.5);
    &:hover {
      background: rgba(white, 0.3);
      color: black;
    }
    cursor: pointer;
    font-size: 14pt;
    &.active,
    &:hover.active {
      background: rgba(white, 0.7);
      color: black;
    }
  }
  img {
    width: 250px;
    margin: auto 25px;
  }
  .id-links {
    display: flex;
    justify-content: space-between;

    span {
      display: inline-block;
      padding: 0.5rem 1rem;
    }
    a,
    a:hover,
    a:link,
    a:visited {
      color: var(--body-text-color);
    }
  }
  .progress {
    margin: 20px 1rem;
  }
  .logout a {
    text-decoration: none;
    &:hover {
      color: white;
    }
  }
</style>
