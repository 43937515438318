<template>
  <a class="v-button" :class="{ secondary }" @click.prevent="$emit('click')"><slot /></a>
</template>

<script>
  export default {
    props: {
      secondary: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../vars';
  a {
    text-decoration: none;
    display: block;
    border-radius: 8px;
    border: 1px solid $purple-100;
    color: #FFF;
    background-color: transparent;
    text-align: center;
    letter-spacing: 0.1ch;
    padding: 1em 2em;
    cursor: pointer;
    margin: auto;
  }
  a:hover {
    background-color: $purple-200;
    border-color: #FFF;
  }
  a.secondary {
    border-color: transparent;
    padding: 1em 1.5em;
    color: #DDD;
    &:hover {
      color: #FFF;
      background-color: transparent;
    }
  }
</style>
