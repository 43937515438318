<template>
  <div class="type-activity" :class="{ correct, incorrect }">
    <div class="item-display">{{ item.gloss }}</div>
    <div class="item-display-back" v-if="correct === false">{{ item.word }}</div>
    <div class="item-display-input" v-if="!(input === '' && correct === false)">
      <template v-if="correct === null">
        <div class="input-group">
          <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" type="text" :disabled="correct !== null" v-model="input" placeholder="Type in answer" @keypress.enter="onCheck" />
        </div>
        <VButton class="primary" @click="onCheck">SUBMIT</VButton>
        <VButton :secondary="true" @click="onCheck">DON'T KNOW</VButton>
      </template>
      <div v-else :class="{ correct, incorrect }" class="answer">{{ input }}</div>
    </div>
    <div class="response-row" v-if="correct === true">
      <RatingButton :kind="hard" @responded="onRespond">hard</RatingButton>
      <RatingButton :kind="justRight" @responded="onRespond">just right</RatingButton>
      <RatingButton :kind="easy" @responded="onRespond">easy</RatingButton>
    </div>
    <template v-else-if="correct === false">
      <div class="response-row">
        <RatingButton :kind="didntKnow" @responded="onRespond">didn’t know it</RatingButton>
        <RatingButton :kind="hard" @responded="onRespond">knew it</RatingButton>
      </div>
      <div class="response-row">
        <RatingButton :kind="justRight" @responded="onRespond" v-if="input !== ''">my answer should be marked correct</RatingButton>
      </div>
    </template>
    <ItemFeedbackForm :item="item" />
  </div>
</template>

<script>
  import {
    FIRST_RESPONSE_YES_WRONG,
    FIRST_RESPONSE_YES_KNOW_IT,
    FIRST_RESPONSE_YES_BARELY_KNOW_IT,
    FIRST_RESPONSE_YES_WONT_FORGET,
    FIRST_RESPONSE_MAYBE_YES,
    FIRST_RESPONSE_MAYBE_NO,
    FIRST_RESPONSE_NO_HARD,
    FIRST_RESPONSE_NO_OKAY,
    RESPONSE_DIDNT_KNOW,
    RESPONSE_JUST_RIGHT,
    RESPONSE_HARD,
    RESPONSE_EASY,
    RESPONSE_STILL_STRUGGLING,
    RESPONSE_GOT_IT,
  } from '../../constants';

  import RatingButton from './RatingButton.vue';
  import ItemFeedbackForm from './ItemFeedbackForm.vue';
  import VButton from '../VButton.vue';

  export default {
    props: ['item', 'knowIt', 'lastItem', 'showings'],
    data() {
      return {
        input: '',
        correct: null,
        shownAt: null,
        revealedAt: null,
      };
    },
    components: {
      RatingButton,
      ItemFeedbackForm,
      VButton,
    },
    mounted() {
      this.shownAt = new Date();
    },
    methods: {
      onRespond(response) {
        this.$emit('responded', {
          response,
          input: this.input,
          activityType: 'type-in',
          shownAt: this.shownAt,
          revealedAt: this.revealedAt,
          cb: () => {
            this.input = '';
            this.correct = null;
          },
        });
      },
      onCheck() {
        this.correct = this.item.word === this.input;
        this.revealedAt = new Date();
      },
    },
    computed: {
      incorrect() {
        return (this.input !== '' && this.correct === false);
      },
      firstSeen() {
        return this.item.lastSeen === null;
      },
      showTypeIn() {
        return !this.firstSeen; // make this a random choice after not firstSeen
      },
      noHard() {
        return FIRST_RESPONSE_NO_HARD;
      },
      noOkay() {
        return FIRST_RESPONSE_NO_OKAY;
      },
      maybeNo() {
        return FIRST_RESPONSE_MAYBE_NO;
      },
      maybeYes() {
        return FIRST_RESPONSE_MAYBE_YES;
      },
      yesWrong() {
        return FIRST_RESPONSE_YES_WRONG;
      },
      yesKnowIt() {
        return FIRST_RESPONSE_YES_KNOW_IT;
      },
      yesBarelyKnowIt() {
        return FIRST_RESPONSE_YES_BARELY_KNOW_IT;
      },
      yesWontForget() {
        return FIRST_RESPONSE_YES_WONT_FORGET;
      },
      didntKnow() {
        return RESPONSE_DIDNT_KNOW;
      },
      justRight() {
        return RESPONSE_JUST_RIGHT;
      },
      hard() {
        return RESPONSE_HARD;
      },
      easy() {
        return RESPONSE_EASY;
      },
      stillStruggling() {
        return RESPONSE_STILL_STRUGGLING;
      },
      gotIt() {
        return RESPONSE_GOT_IT;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../vars';

  input {
      width: 100%;
      border: none;
      border-radius: 3px;
      padding: 1rem;
      outline: none;
      background-color: var(--body-background-color);
      border: 1px solid #556;
      &:focus {
        border-color: #778;
      }
      color: #FFF;
      font-size: 1.5rem;
  }
  .answer.incorrect {
    color: $answer-incorrect;
    text-align: center;
    flex: 1;
    font-size: 2rem;
  }
  .answer.correct {
    color: $answer-correct;
    text-align: center;
    flex: 1;
    font-size: 2rem;
  }
  .item-display {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .item-display-back {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 40px;
  }
  .response-row {
    text-align: center;
    margin-bottom: 40px;
  }
  .response-row > * {
    padding: 1rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .item-display-input {
    display: flex;
    margin: 40px 30px;
  }
  .type-activity::v-deep a.v-button {
    padding: 1.75em 2em;
    margin-bottom: auto;
    a.secondary {
      padding: 1.7em 1.5em;
    }
  }
  .input-group {
    position: relative;
    flex: 1;
    display: flex;
    margin-right: 10px;
    .icon {
      position: absolute;
      right: 10px;
      top: calc(50% - 13px);
      font-size: 16pt;
    }
    .help-text,
    .icon {
      display: none;
    }
    .help-text {
      text-align: center;
      margin-top: 2px;
    }
    &.correct .icon {
      display: inline;
      color: $green;
    }
    &.incorrect .icon {
      display: inline;
      color: $red;
    }
    &.correct .help-text {
      display: block;
      color: $green;
    }
    &.incorrect .help-text {
      display: block;
      color: $red;
    }
  }

  @media (max-width: 1024px) {
    .item-display-input {
      margin: 40px 0px;
      flex-wrap: wrap;
    }
    .input-group {
      width: 100%;
      flex: auto;
      margin-right: 0;
    }
    .type-activity::v-deep a.v-button {
      padding: 1.5em 2em;
      margin-top: 1rem;
      margin-bottom: auto;
      flex: 1;
      &.primary {
        order: 3;
      }
      &.secondary {
        order: 2;
        padding: 1.7em 1.5em;
      }
    }
  }
</style>
