<template>
  <div class="session-start-button">
    <div class="processing" v-if="processing">Constructing session...</div>
    <ActionButton v-else @click="onStart" :block="true" kind="success">
      Start a session
    </ActionButton>
    <div class="typing-checkbox" :class="{ visible: (itemsAreDue && !processing) }">
      <input type="checkbox" v-model="typingActivity" id="typingActivity">
      <label for="typingActivity">Include experimental typing activities</label>
    </div>
  </div>
</template>

<script>
  import { START_SESSION } from '../../constants';
  import ActionButton from '../ActionButton.vue';

  export default {
    components: { ActionButton },
    data() {
      return {
        processing: false,
        typingActivity: false,
      };
    },
    methods: {
      onStart() {
        this.processing = true;
        const { typingActivity } = this;
        this.$store.dispatch(START_SESSION, { typingActivity }).then(() => {
          this.$gtag.event('start_session');
          this.$mixpanel.track('StartSession', { typingActivity });
          this.$mixpanel.time_event('CancelSession');
          this.$mixpanel.time_event('EndSession');
          this.processing = false;
        });
      },
    },
    computed: {
      itemsAreDue() {
        const { overdue, due } = this.$store.getters.dueItemCounts;
        return (overdue + due) > 0;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .processing {
    text-align: center;
    margin: 1rem;
    padding: 0.5rem;
    font-style: italic;
  }
  .typing-checkbox {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
  .session-start-button {
    font-size: 1.5rem;
    .typing-checkbox {
      text-align: center;
    }
    label {
     font-size: 0.8rem;
    }
  }
</style>
