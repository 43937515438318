import { v4 } from 'uuid';
import * as Sentry from '@sentry/browser';

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  START_SESSION,
  SELECT_SESSION,
  SELECT_ITEM,
  RECORD_RESPONSE,
  ACTION_DONE,
  ACTION_PUT_BACK,
  ACTION_TEST_OUT,
  FETCH_ITEMS,
  SET_DAY,
  END_SESSION,
  CANCEL_SESSION,
  RESPONSE_PUT_BACK,
  FIRST_RESPONSE_DONT_KNOW,
  FETCH_USER_ITEMS,
  FETCH_SESSIONS,
  SET_LANGUAGE,
  FETCH_PROFILE,
  SETTING_LANGUAGE,
  AUTH_PASSWORD_RESET_CONFIRM,
  SELECT_USER,
  TOGGLE_ADMIN_MODE,
  RESPONSE_DIDNT_KNOW,
} from './constants';
import histograms from './histograms';
import algos from './algos';
import api from './api';

const EPOCH_START_DAY = new Date(2020, 8, 1);
const MILLISECONDS_IN_DAY = 8.64e7;

const daysSinceEpoch = () => Math.floor((new Date() - EPOCH_START_DAY) / MILLISECONDS_IN_DAY);

const authToken = localStorage.getItem('user-token');
if (authToken) {
  api.setAuthHeader(authToken);
}

const mapOrder = (state, name, items, idField = 'id') => {
  state[`${name}Order`] = items.map(item => item[idField]);
  state[name] = items.reduce((map, item) => ({
    ...map,
    [item[idField]]: item,
  }), {});
};

export default function createStore() {
  return {
    state: {
      version: process.env.COMMIT_REF,
      userId: null,
      email: null,
      hasCompletedSessions: false,
      isStaff: false,
      adminMode: false,
      authToken: authToken || '',
      authError: '',
      day: daysSinceEpoch(),
      studyingLanguage: null,
      availableLanguages: [],
      learningTree: { items: [], children: [] },
      settingLanguage: false,
      showTypingActivity: false,
      selectedUser: null,
      selectedUserLang: null,

      items: {},
      itemsOrder: [],
      userItems: {},
      userItemsOrder: [],
      sessions: {},
      sessionsOrder: [],

      showings: {},
      showingsOrder: [],

      inProgressItems: [],
      selectedSessionId: null,
      selectedItemId: null,
      activeSessionId: null,
    },
    getters: {
      currentLanguage: state => state.selectedUserLang || state.studyingLanguage,
      firstSeen: (_, getters) => item => getters.showings.filter(s => s.item === item.id).length === 0,
      items: (state, getters) => state.itemsOrder.map(id => ({
        ...state.items[id],
        ...state.userItems[id],
      })).filter(item => item.lang === getters.currentLanguage),
      nextDueCounts: (_, getters) => getters.items.reduce((map, item) => {
        if (map[item.nextDue] === undefined) {
          map[item.nextDue] = 0;
        }
        map[item.nextDue] += 1;
        return map;
      }, {}),
      seenItems: (_, getters) => getters.items.filter(item => !!item.nextDue),
      neverSeenItems: (_, getters) => getters.items.filter(item => !item.nextDue),
      overdueItems: (state, getters) => getters.seenItems.filter(item => item.nextDue - state.day < 0),
      dueItems: (state, getters) => getters.seenItems.filter(item => item.nextDue - state.day === 0),
      dueTomorrowItems: (state, getters) => getters.seenItems.filter(item => item.nextDue - state.day === 1),
      notDueItems: (state, getters) => getters.seenItems.filter(item => item.nextDue - state.day > 0),
      dueItemCounts: (_, getters) => {
        const neverSeen = getters.neverSeenItems.length;
        const overdue = getters.overdueItems.length;
        const due = getters.dueItems.length;
        const notDue = getters.notDueItems.length;
        return {
          overdue,
          due,
          notDue,
          neverSeen,
        };
      },
      seenItemCounts: (_, getters) => histograms.seenCounts(getters.itemSeenMap),
      itemSeenMap: (_, getters) => getters.items.reduce((map, item) => ({
        ...map,
        [item.id]: getters.sessionItemShowings(item),
      }), {}),
      itemShowingsMap: (_, getters) => getters.showings.reduce((map, showing) => {
        const showings = map[showing.item] || [];
        return {
          ...map,
          [showing.item]: [...showings, showing],
        };
      }, {}),
      streakItemCounts: (_, getters) => histograms.streakCounts(getters.itemShowingsMap),
      countsByDueDates: (state, getters) => {
        const itemsDue = getters.items.filter(item => !!item.nextDue);
        return histograms.dueCounts(itemsDue, state.day);
      },
      countsOfStrength: (_, getters) => histograms.strengthCounts(getters.items),
      activeSession: state => state.sessions[state.activeSessionId],
      selectedSession: state => state.sessions[state.selectedSessionId],
      sessions: (state, getters) => state.sessionsOrder.map(id => state.sessions[id]).filter(session => {
        const items = session.items
          .map(itemId => state.items[itemId])
          .filter(item => item && item.lang === getters.currentLanguage);
        return items.length > 0;
      }),
      allShowings: state => state.showingsOrder.map(id => state.showings[id]),
      todaysShowings: (state, getters) => getters.allShowings.filter(showing => showing.when === state.day),
      showings: (_, getters) => {
        const endedSessions = getters.sessions.filter(session => session.endedAt !== null);
        return endedSessions.map(session => session.showings).flat();
      },
      sessionItemShowings: (_, getters) => item => {
        const itemShowings = getters.itemShowingsMap[item.id];
        if (itemShowings === undefined) {
          return [];
        }
        const showings = itemShowings.reduce((map, showing) => {
          if (map[showing.session]) {
            map[showing.session].push(showing);
          } else {
            map[showing.session] = [showing];
          }
          return map;
        }, {});

        return Object.keys(showings)
          .map(sessionId => showings[sessionId][0])
          .filter(showing => showing !== undefined);
      },
      itemsFirstSeenToday: (state, getters) => getters.seenItems
        .filter(item => item.firstResponse !== null)
        .filter(item => item.firstResponse.when === state.day),
      newItemsUnknownSeenToday: (_, getters) => getters.itemsFirstSeenToday
        .filter(item => FIRST_RESPONSE_DONT_KNOW.indexOf(item.firstResponse.response) > -1),
      newItemsLiveCount: (_, getters) => [...new Set([
        ...getters.newItemsUnknownSeenToday.map(item => item.id),
        ...getters.todaysShowings
          .filter(showing => FIRST_RESPONSE_DONT_KNOW.indexOf(showing.response) > -1)
          .map(showing => showing.item),
      ])].length,
      itemsForNextSession: (_, getters) => {
        const {
          overdueItems,
          dueItems,
          neverSeenItems,
        } = getters;
        return algos.itemsForSession(overdueItems, dueItems, neverSeenItems);
      },
      todaysCompletedShowings: (state, getters) => getters.showings.filter(s => s.when === state.day),
      itemsShownToday: (_, getters) => getters.seenItems
        .filter(item => getters.todaysCompletedShowings.filter(showing => showing.item === item.id).length > 0),
      repeatedToday: (state, getters) => {
        // cards seen today had been seen before
        const showingsNotToday = getters.showings.filter(s => s.when !== state.day);
        return getters.itemsShownToday
          .filter(item => showingsNotToday.filter(s => s.item === item.id).length > 0);
      },
      forgotToday: (_, getters) => getters.repeatedToday
        .filter(item => getters.todaysCompletedShowings
          .filter(showing => showing.item === item.id)
          .filter(showing => showing.response === RESPONSE_DIDNT_KNOW).length > 0),
      forgotTodayLiveCount: (_, getters) => [...new Set([
        ...getters.forgotToday.map(item => item.id),
        ...getters.todaysShowings
          .filter(showing => showing.response === RESPONSE_DIDNT_KNOW)
          .map(showing => showing.item),
      ])].length,
    },
    mutations: {
      [AUTH_LOGIN]: (state, { data, errors }) => {
        if (errors) {
          state.authToken = '';
          localStorage.removeItem('user-token');
          api.removeAuthHeader();
          state.studyingLanguage = null;
          state.email = null;
          Sentry.configureScope(scope => scope.setUser(null));
          state.availableLanguages = [];
          state.isStaff = false;
          state.hasCompletedSessions = false;
          state.adminMode = false;
          state.authError = errors;
          state.userId = null;
        } else {
          state.authToken = data.token;
          localStorage.setItem('user-token', data.token);
          api.setAuthHeader(data.token);
          state.studyingLanguage = data.studyingLanguage;
          state.email = data.email;
          Sentry.configureScope(scope => scope.setUser({ email: data.email }));
          state.availableLanguages = data.availableLanguages;
          state.isStaff = data.staff;
          state.hasCompletedSessions = data.hasCompletedSessions;
          state.adminMode = false;
          state.authError = '';
          state.userId = data.id;
        }
      },
      [AUTH_LOGOUT]: state => {
        state.authToken = '';
        localStorage.removeItem('user-token');
        api.removeAuthHeader();

        // Clear local state
        state.day = daysSinceEpoch();
        state.studyingLanguage = null;
        state.availableLanguages = [];
        state.userId = null;
        state.email = null;
        Sentry.configureScope(scope => scope.setUser(null));
        state.isStaff = false;
        state.hasCompletedSessions = false;
        state.adminMode = false;
        state.showingsOrder = [];
        state.showings = {};
        state.inProgressItems = [];
        state.selectedSessionId = null;
        state.selectedItemId = null;
        state.activeSessionId = null;
        state.items = {};
        state.itemsOrder = [];
        state.userItems = {};
        state.userItemsOrder = [];
        state.sessions = {};
        state.sessionsOrder = [];
      },
      [SET_DAY]: (state, day) => {
        state.day = day;
      },
      [FETCH_ITEMS]: (state, data) => {
        state.itemsOrder = Object.freeze(data.itemsOrder);
        state.items = Object.freeze(data.items);
        state.learningTree = Object.freeze(data.tree);
      },
      [FETCH_USER_ITEMS]: (state, data) => {
        mapOrder(state, 'userItems', data, 'item');
      },
      [FETCH_SESSIONS]: (state, data) => {
        mapOrder(state, 'sessions', data);
      },
      [START_SESSION]: (state, { session, userItems, typingActivity }) => {
        state.inProgressItems = session.items;
        state.sessionsOrder = [...state.sessionsOrder, session.id];
        state.sessions = { ...state.sessions, [session.id]: session };
        state.activeSessionId = session.id;
        state.showTypingActivity = typingActivity;
        state.userItemsOrder = Object.freeze([
          ...state.userItemsOrder,
          ...userItems.map(ui => ui.item),
        ]);
        state.userItems = Object.freeze({
          ...state.userItems,
          ...userItems.reduce((map, item) => ({
            ...map,
            [item.item]: item,
          }), {}),
        });
      },
      [SELECT_SESSION]: (state, id) => {
        state.selectedSessionId = id;
      },
      [SELECT_ITEM]: (state, id) => {
        state.selectedItemId = id;
      },
      [RECORD_RESPONSE]: (state, {
        item, response, input, activityType, shownAt, revealedAt,
      }) => {
        const sessionId = state.activeSessionId;
        const inProgress = [...state.inProgressItems];
        let action;

        if (RESPONSE_PUT_BACK.indexOf(response) > -1) {
          if (inProgress.length > 2) {
            [inProgress[0], inProgress[1]] = [inProgress[1], inProgress[0]];
            action = ACTION_PUT_BACK;
          } else {
            inProgress.splice(0, 1);
            action = ACTION_TEST_OUT;
          }
        } else {
          inProgress.splice(0, 1);
          action = ACTION_DONE;
        }

        state.inProgressItems = inProgress;

        const showing = {
          id: v4(),
          when: state.day,
          name: `Showing #${state.showingsOrder.length + 1}`,
          item: item.id,
          response,
          shownAt,
          revealedAt,
          respondedAt: new Date(),
          session: sessionId,
          action,
          answer: { input },
          activityType,
        };
        state.showingsOrder = [...state.showingsOrder, showing.id];
        state.showings = { ...state.showings, [showing.id]: showing };
      },
      [CANCEL_SESSION]: (state, { userItems }) => {
        state.userItems = Object.freeze({
          ...state.userItems,
          ...userItems.reduce((map, item) => ({
            ...map,
            [item.item]: item,
          }), {}),
        });
        state.sessionsOrder = [...state.sessionsOrder.filter(id => id !== state.activeSessionId)];
        state.sessions = state.sessionsOrder.reduce((map, id) => ({
          ...map,
          [id]: state.sessions[id],
        }), {});

        state.showingsOrder = [];
        state.showings = {};
        state.activeSessionId = null;
        state.inProgressItems = [];
      },
      [END_SESSION]: (state, { session, userItems }) => {
        state.hasCompletedSessions = true;
        state.activeSessionId = null;
        state.sessions = {
          ...state.sessions,
          [session.id]: session,
        };
        const sessionUserItems = userItems.reduce((map, item) => ({
          ...map,
          [item.item]: item,
        }), {});
        state.userItems = Object.freeze({
          ...state.userItems,
          ...sessionUserItems,
        });
        state.showingsOrder = [];
        state.showings = {};
      },
      [SETTING_LANGUAGE]: state => {
        state.settingLanguage = true;
        state.studyingLanguage = null;
        state.day = daysSinceEpoch();
        state.inProgressItems = [];
        state.selectedSessionId = null;
        state.selectedItemId = null;
        state.activeSessionId = null;
      },
      [SET_LANGUAGE]: (state) => { state.settingLanguage = false; },
      [FETCH_PROFILE]: (state, data) => {
        state.userId = data.id;
        state.email = data.email;
        Sentry.configureScope(scope => scope.setUser({ email: data.email }));
        state.isStaff = data.staff;
        state.hasCompletedSessions = data.hasCompletedSessions;
        state.studyingLanguage = data.studyingLanguage;
        state.availableLanguages = data.availableLanguages;
      },
      [SELECT_USER]: (state, { user, lang }) => {
        if (state.selectedUser === user.id && state.selectedUserLang === lang) {
          state.selectedUser = null;
          state.selectedUserLang = null;
        } else {
          state.selectedUser = user.id;
          state.selectedUserLang = lang;
        }
      },
      [TOGGLE_ADMIN_MODE]: state => {
        state.adminMode = !state.adminMode;
      },
    },
    actions: {
      [AUTH_PASSWORD_RESET_CONFIRM]: ({ commit }, { password, token }) => api.setPassword(password, token).then(data => commit(AUTH_LOGIN, data)),
      [AUTH_LOGIN]: ({ commit }, { email, password }) => api.login(email, password).then(data => commit(AUTH_LOGIN, data)),
      [AUTH_LOGOUT]: ({ commit }) => commit(AUTH_LOGOUT),
      [CANCEL_SESSION]: ({ commit, state }) => api.cancelSession(state.activeSessionId)
        .then(data => commit(CANCEL_SESSION, data.data)),
      [END_SESSION]: ({ commit, state, getters }) => {
        const currentShowings = state.showingsOrder.map(id => state.showings[id]);
        const shownItemIds = [...new Set(currentShowings.map(showing => showing.item))];
        let userItems = { ...state.userItems };
        shownItemIds.forEach(itemId => {
          const itemShowings = currentShowings.filter(showing => showing.item === itemId && showing.session === state.activeSessionId);
          const firstSessionShowing = itemShowings[0];
          const lastSessionShowing = itemShowings[itemShowings.length - 1];
          const newStrength = algos.calcStrength(firstSessionShowing.response, state.userItems[itemId].strength);
          const nextDue = lastSessionShowing.action === ACTION_TEST_OUT
            ? state.day
            : algos.nextDue(state.day, getters.nextDueCounts, newStrength);
          userItems = {
            ...userItems,
            [itemId]: {
              ...userItems[itemId],
              nextDue,
              strength: newStrength,
            },
          };
        });

        userItems = Object.keys(userItems)
          .filter(itemId => shownItemIds.indexOf(itemId) > -1)
          .map(itemId => userItems[itemId]);

        return api.endSession(state.activeSessionId, userItems, currentShowings)
          .then(data => commit(END_SESSION, data.data));
      },
      [SET_DAY]: ({ commit }, { day }) => commit(SET_DAY, day),
      [FETCH_ITEMS]: ({ commit, state }) => api.getItems(state.selectedUser, state.selectedUserLang).then(data => commit(FETCH_ITEMS, data.data)),
      [FETCH_USER_ITEMS]: ({ commit, state }) => api.getUserItems(state.selectedUser, state.selectedUserLang).then(data => commit(FETCH_USER_ITEMS, data.data)),
      [FETCH_SESSIONS]: ({ commit, state }) => api.getSessions(state.selectedUser, state.selectedUserLang).then(data => commit(FETCH_SESSIONS, data.data)),
      [START_SESSION]: ({ state, commit, getters }, { typingActivity }) => api
        .createSession(state.day, getters.itemsForNextSession)
        .then(data => {
          const sessionData = {
            ...data.data,
            typingActivity,
          };
          commit(START_SESSION, sessionData);
        }),
      [SELECT_SESSION]: ({ commit }, { id }) => commit(SELECT_SESSION, id),
      [SELECT_ITEM]: ({ commit }, { id }) => commit(SELECT_ITEM, id),
      [RECORD_RESPONSE]: ({ commit }, {
        item, response, input, activityType, shownAt, revealedAt,
      }) => commit(RECORD_RESPONSE, {
        item, response, input, activityType, shownAt, revealedAt,
      }),
      [SET_LANGUAGE]: ({ commit }, { language }) => {
        commit(SETTING_LANGUAGE);
        return api.setLanguage(language).then(data => {
          commit(FETCH_PROFILE, data.profile);
          commit(FETCH_ITEMS, data);
          commit(FETCH_USER_ITEMS, data.userItems);
          commit(SET_LANGUAGE);
        });
      },
      [SELECT_USER]: ({ commit }, { user, lang }) => commit(SELECT_USER, { user, lang }),
      [FETCH_PROFILE]: ({ commit }) => api.getProfile().then(data => commit(FETCH_PROFILE, data.data)),
      [TOGGLE_ADMIN_MODE]: ({ commit }) => commit(TOGGLE_ADMIN_MODE),
    },
  };
}
