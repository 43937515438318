<template>
  <tr :key="sessionItem.id">
    <th>{{ session.name }}</th>
    <td>day {{ session.when }}</td>
    <td class="history">
      <RatingBall v-for="(rating, index) in ratings" :key="index" :rating="rating" />
    </td>
    <td>day {{ nextDue }}</td>
  </tr>
</template>

<script>
  import RatingBall from '../RatingBall.vue';

  export default {
    props: ['session', 'item'],
    components: { RatingBall },
    computed: {
      nextDue() {
        return this.sessionItem && this.sessionItem.nextDue;
      },
      sessionItem() {
        return this.session.sessionItems.filter(si => si.item === this.item.id)[0];
      },
      showings() {
        return this.session.showings
          .filter(showing => showing.item === this.item.id);
      },
      ratings() {
        return this.showings
          .map(showing => showing.response);
      },
    },
  };
</script>

<style lang="scss" scoped>
  td.history {
    text-align: left;
  }
</style>
