<template>
  <Card class="card test-card" :title="sessionComplete ? 'Session Completed' : null">
    <p v-if="inFirstSession">Welcome, to your first session!</p>

    <!-- Session has started and is active -->
    <div class="session" v-if="inSession">
      <TestItem :key="item.id" :canceling="canceling" :item="item" :last-item="lastItem" :showings="showings" />
      <!-- Cancel Button -->
      <CancelSessionButton @canceling="canceling = true" />
    </div>

    <!-- Session has completed but not submitted -->
    <template v-else-if="sessionComplete">
      <!-- Close/End Session Button -->
      <CloseSessionButton />
      <SessionStats />
    </template>

    <!-- There is no active session  -->
    <DailyStats v-else>
      <!-- Start Button -->
      <StartSessionButton v-if="canStartSession" />
      <p class="no-study" v-else>No items to study at the moment.</p>
    </DailyStats>
  </Card>
</template>

<script>
  import Card from '../Card.vue';
  import TestItem from '../TestItem/TestItem.vue';
  import StartSessionButton from './StartSessionButton.vue';
  import CloseSessionButton from './CloseSessionButton.vue';
  import CancelSessionButton from './CancelSessionButton.vue';
  import SessionStats from './SessionStats.vue';
  import DailyStats from './DailyStats.vue';
  import { NEW_SEEN_DAILY_MAX } from '../../constants';

  export default {
    components: {
      Card,
      TestItem,
      StartSessionButton,
      CloseSessionButton,
      CancelSessionButton,
      DailyStats,
      SessionStats,
    },
    data() {
      return {
        canceling: false,
      };
    },
    watch: {
      session() {
        this.canceling = false;
      },
    },
    computed: {
      forgotLiveCount() {
        return this.$store.getters.forgotTodayLiveCount;
      },
      newTodayCount() {
        return this.$store.getters.newItemsLiveCount;
      },
      seenEnough() {
        return (this.newTodayCount + this.forgotLiveCount) >= NEW_SEEN_DAILY_MAX;
      },
      session() {
        return this.$store.getters.activeSession || null;
      },
      dueCount() {
        const {
          dueItems,
          overdueItems,
        } = this.$store.getters;
        return dueItems.length + overdueItems.length;
      },
      canStartSession() {
        return (this.dueCount > 0 || !this.seenEnough) && (this.session === null || this.session.endedAt !== null);
      },
      inSession() {
        if (this.seenEnough) {
          const items = {
            ...this.$store.state.items,
            ...this.$store.state.userItems,
          };
          return this.$store.state.inProgressItems
            .map(id => items[id])
            .filter(item => {
              const showings = this.todaysShowings.filter(showing => showing.item === item.id);
              return this.item.lastSeen !== null || showings.length > 0;
            }).length > 0;
        }
        return this.$store.state.inProgressItems.length > 0;
      },
      todaysShowings() {
        return this.$store.state.showingsOrder
          .map(id => this.$store.state.showings[id])
          .filter(showing => showing.when === this.$store.state.day);
      },
      inFirstSession() {
        return !this.$store.state.hasCompletedSessions && !this.item;
      },
      item() {
        const itemId = this.$store.state.inProgressItems[0];
        return {
          ...this.$store.state.items[itemId],
          ...this.$store.state.userItems[itemId],
        };
      },
      nextItems() {
        return this.$store.getters.itemsForNextSession;
      },
      showings() {
        return this.item && this.todaysShowings.filter(showing => showing.item === this.item.id);
      },
      lastItem() {
        return this.$store.state.inProgressItems.length === 1;
      },
      activeSession() {
        return this.$store.getters.activeSession;
      },
      inProgressItems() {
        return this.$store.state.inProgressItems.map(i => this.$store.state.items[i]);
      },
      sessionComplete() {
        return this.session !== null
          && this.session.endedAt === null
          && !this.inSession;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .no-study {
    text-align: center;
    font-size: 1.5rem;
  }
  .session {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .test-card {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
