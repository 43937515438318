import Vue from 'vue';
import Router from 'vue-router';

import Learn from './pages/Learn.vue';
import Login from './pages/Login.vue';
import PasswordReset from './pages/PasswordReset.vue';

Vue.use(Router);

const routes = [
  { path: '/', component: Learn, name: 'learn' },
  { path: '/login/', component: Login, name: 'login' },
  { path: '/password-reset/', component: PasswordReset, name: 'password-reset' },
  { path: '/password-reset/:token/', component: PasswordReset, name: 'password-reset-token' },
  { path: '/confirm-account/:token/', component: PasswordReset, name: 'confirm-account' },
];

export default new Router({ mode: 'history', base: '', routes });
