<template>
  <div class="card-count-row" :class="{ small, flipped }">
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      small: {
        type: Boolean,
        default: false,
      },
      flipped: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-count-row {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    &.small {
      padding: 1rem;
    }
  }
</style>
