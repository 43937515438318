<template>
  <Card class="card items-card" title="Items">
    <div class="time-travel">
      Day: <input v-model.number="days" size="2" @keyup.enter="changeDay" />
    </div>
    <table>
      <thead>
        <tr>
          <th rowspan="2">Item</th>
          <th>history</th>
          <th>times seen</th>
          <th>strength</th>
          <th>last seen</th>
          <th>next due</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :item="item" :key="item.id" @click.prevent="onSelect(item.id)">
          <th>{{ item.word }}</th>
          <td class="history">
            <RatingBall v-for="(rating, index) in ratings(item)" :key="index" :rating="rating" />
          </td>
          <td>{{ timesSeen(item) }}</td>
          <td><StrengthNumber v-if="item.strength !== null" :strength="item.strength" /></td>
          <td>{{ item.lastSeen }}</td>
          <td>{{ nextDue(item) }}</td>
        </tr>
      </tbody>
    </table>
  </Card>
</template>

<script>
  import Card from '../Card.vue';
  import StrengthNumber from '../StrengthNumber.vue';
  import RatingBall from '../RatingBall.vue';

  import { SELECT_ITEM, SET_DAY } from '../../constants';

  export default {
    components: { Card, RatingBall, StrengthNumber },
    methods: {
      onSelect(id) {
        this.$store.dispatch(SELECT_ITEM, { id });
      },
      ratings(item) {
        return this.showingsForItem(item).reduce((ratings, obj) => {
          ratings.push(obj.response);
          return ratings;
        }, []);
      },
      showingsForItem(item) {
        return this.$store.getters.sessionItemShowings(item);
      },
      timesSeen(item) {
        return this.showingsForItem(item).length;
      },
      nextDue(item) {
        const userItem = this.$store.state.userItems[item.id];
        return (userItem && userItem.nextDue) || 'never seen';
      },
      changeDay() {
        this.$store.dispatch(SET_DAY, { day: parseInt(this.days, 10) });
      },
    },
    data() {
      return {
        days: this.$store.state.day,
      };
    },
    computed: {
      items() {
        return this.$store.getters.items;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .time-travel {
    text-align: right;
    margin-bottom: 5px;
    input {
      text-align: right;
      background: none;
      border: none;
      color: var(--body-text-color);
      font-size: 1rem;
      outline: none;
    }
  }
</style>
