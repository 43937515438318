<template>
  <div class="item-feedback-form">
    <div v-if="saving">saving feedback...</div>
    <div v-else-if="leavingFeedback" class="form">
      <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="model.comments" @keypress.enter="onSubmit" type="text" ref="comments" />
      <VButton @click="onSubmit">SUBMIT</VButton>
      <VButton @click="onCancel" :secondary="true">CANCEL</VButton>
    </div>
    <div v-else class="report-label" @click.prevent="onLeaveFeedback">report issue with gloss</div>
  </div>
</template>

<script>
  import api from '../../api';
  import VButton from '../VButton.vue';

  export default {
    props: ['item'],
    components: { VButton },
    data() {
      return {
        saving: false,
        leavingFeedback: false,
        model: {
          comments: '',
        },
      };
    },
    methods: {
      onLeaveFeedback() {
        this.leavingFeedback = true;
        this.$nextTick(() => {
          this.$refs.comments.focus();
        });
      },
      onCancel() {
        this.leavingFeedback = false;
      },
      onSubmit() {
        this.saving = true;
        api.leaveFeedback(this.item.id, this.model.comments)
          .then(() => {
            this.saving = false;
            this.leavingFeedback = false;
            this.comments = '';
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .item-feedback-form {
    margin-top: 20px;
  }
  .report-label {
    text-align: center;
    cursor: pointer;
    color: rgba(white, 0.5);

    &:hover {
      color: white;
    }
  }
  .form {
    display: flex;
    justify-content: space-between;
    input {
      flex: 1;
      border: none;
      border-radius: 3px;
      padding: 0 1rem;
      outline: none;
      margin-right: 0.5rem;
      background-color: var(--body-background-color);
      border: 1px solid #556;
      &:focus {
        border-color: #778;
      }
      color: #FFF;
      font-size: 1rem;
    }
    button {
      border-radius: 3px;
      border: none;
      cursor: pointer;
    }
  }
</style>
