<template>
  <Card class="card item-card" :title="title" :closeable="true" @closed="onClose">
    <StatsBoxRow :small="true" :flipped="true">
      <StatsBox :stat="nextDue">due</StatsBox>
      <StatsBox :stat="timesSeen">times seen</StatsBox>
      <StatsBox :stat="lastSeen">last seen</StatsBox>
      <StatsBox :stat="correctStreaks">correct streaks</StatsBox>
      <StatsBox v-if="item.strength !== null">
        strength
        <StrengthNumber slot="num" :strength="item.strength" />
      </StatsBox>
    </StatsBoxRow>

    <h4>Sessions</h4>

    <table>
      <thead>
        <tr>
          <th>Session</th>
          <th>When</th>
          <th>History</th>
          <th>Due Date Set</th>
        </tr>
      </thead>
      <tbody>
        <SessionRow v-for="(session, index) in sessions" :key="index" :session="session" :item="item" />
      </tbody>
    </table>
  </Card>
</template>

<script>
  import Card from '../Card.vue';
  import StatsBox from '../StatsBox.vue';
  import StatsBoxRow from '../StatsBoxRow.vue';
  import StrengthNumber from '../StrengthNumber.vue';

  import SessionRow from './SessionRow.vue';
  import algos from '../../algos';
  import { SELECT_ITEM } from '../../constants';

  export default {
    props: ['item'],
    components: {
      Card,
      StatsBox,
      StatsBoxRow,
      SessionRow,
      StrengthNumber,
    },
    methods: {
      onClose() {
        this.$store.dispatch(SELECT_ITEM, { id: null });
      },
    },
    computed: {
      title() {
        return `Item Detail: ${this.item.word}`;
      },
      showings() {
        return this.$store.getters.itemShowingsMap[this.item.id];
      },
      timesSeen() {
        return this.showings.length;
      },
      lastSeen() {
        return this.item.lastSeen && `day ${this.item.lastSeen}`;
      },
      nextDue() {
        const userItem = this.$store.state.userItems[this.item.id];
        return userItem ? `day ${userItem.nextDue}` : 'never seen';
      },
      correctStreaks() {
        return this.showings.reduce((acc, showing) => algos.calculateStreak(showing.response, acc), 0);
      },
      sessions() {
        // return this.$store.getters.sessionItemShowings(this.item);
        return [...new Set(this.showings.map(s => s.session))]
          .map(sessionId => this.$store.state.sessions[sessionId]);
      },
    },
  };
</script>
