<template>
  <div class="learning-tree" :style="level > 0 ? { paddingLeft: `${level * 10}px` } : null">
    <div class="tree-label" @click.prevent="onToggleExpand" >
      <CollapseIcon v-if="tree.children.length > 0" :expand="showChildren" />
      <span class="name">{{ tree.name }}</span>
    </div>
    <WordListProgress v-if="items.length > 0" :items="items" />
    <template v-if="showChildren">
      <LearningTree v-for="child in tree.children" :key="child.id" :tree="child" :level="level + 1" />
    </template>
  </div>
</template>

<script>
  import CollapseIcon from './CollapseIcon.vue';
  import WordListProgress from './WordListProgress.vue';

  const mergeItems = tree => [
    ...tree.items,
    ...tree.children.map(child => mergeItems(child)).flat(),
  ];

  export default {
    name: 'LearningTree',
    components: { CollapseIcon, WordListProgress },
    props: {
      tree: Object,
      level: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        showChildren: false,
      };
    },
    computed: {
      items() {
        return mergeItems(this.tree);
      },
    },
    methods: {
      onToggleExpand() {
        this.showChildren = !this.showChildren;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tree-label {
    margin-top: 10px;
    margin-bottom: 0px;
    cursor: pointer;

    span + span {
      margin-left: 5px;
    }
  }
</style>
