<template>
  <div class="test-item">
    <div class="first-seen" v-if="firstSeen"><span>new word</span></div>

    <div class="canceling" v-if="canceling">Canceling session...</div>

    <component
      class="test-item-component"
      v-else
      :is="activity"
      :item="item"
      :knowIt="knowIt"
      :lastItem="lastItem"
      :showings="showings"
      @responded="onRespond"
    >
      <template v-if="firstSeen">
        <div class="caption">do you know this word?</div>
        <div class="response-row know-it">
          <span class="know-it-no" @click.prevent="onDontKnowIt">No</span>
          <span class="know-it-no" @click.prevent="onMaybeKnowIt">Maybe</span>
          <span class="know-it-yes" @click.prevent="onKnowIt">Yes</span>
        </div>
      </template>
    </component>
  </div>
</template>

<script>
  import {
    FIRST_RESPONSE_YES_WRONG,
    FIRST_RESPONSE_YES_KNOW_IT,
    FIRST_RESPONSE_YES_BARELY_KNOW_IT,
    FIRST_RESPONSE_YES_WONT_FORGET,
    FIRST_RESPONSE_MAYBE_YES,
    FIRST_RESPONSE_MAYBE_NO,
    FIRST_RESPONSE_NO_HARD,
    FIRST_RESPONSE_NO_OKAY,
    RESPONSE_DIDNT_KNOW,
    RESPONSE_JUST_RIGHT,
    RESPONSE_HARD,
    RESPONSE_EASY,
    RESPONSE_STILL_STRUGGLING,
    RESPONSE_GOT_IT,
    RECORD_RESPONSE,
  } from '../../constants';

  import RatingButton from './RatingButton.vue';
  import ItemFeedbackForm from './ItemFeedbackForm.vue';
  import FlashCardActivity from './FlashCardActivity.vue';
  import TypeActivity from './TypeActivity.vue';

  export default {
    props: ['item', 'lastItem', 'showings', 'canceling'],
    data() {
      return {
        knowIt: null,
        revealedAt: null,
      };
    },
    components: {
      RatingButton,
      ItemFeedbackForm,
    },
    watch: {
      item: {
        immediate: true,
        handler() {
          if (this.item) {
            this.$gtag.event('view_item');
            this.$mixpanel.track('ViewItem', { itemId: this.item.id });
            this.$mixpanel.time_event('RecordResponse');
          }
        },
      },
    },
    methods: {
      onRespond({
        response, input, activityType, shownAt, revealedAt, cb,
      }) {
        this.$store
          .dispatch(RECORD_RESPONSE, {
            item: this.item,
            response,
            input,
            activityType,
            shownAt,
            revealedAt: revealedAt === null ? this.revealedAt : revealedAt,
          })
          .then(() => {
            this.knowIt = null;
            this.$mixpanel.track('RecordResponse', {
              item: this.item.id,
              response,
              input,
              activityType,
              shownAt,
              revealedAt: revealedAt === null ? this.revealedAt : revealedAt,
            });
            cb();
          });
      },
      onKnowIt() {
        this.knowIt = 'yes';
        this.revealedAt = new Date();
      },
      onMaybeKnowIt() {
        this.knowIt = 'maybe';
        this.revealedAt = new Date();
      },
      onDontKnowIt() {
        this.knowIt = 'no';
        this.revealedAt = new Date();
      },
    },
    computed: {
      firstSeen() {
        return this.item.lastSeen === null && this.showings.length === 0;
      },
      showTypeIn() {
        if (!this.$store.state.showTypingActivity) {
          return false;
        }
        return this.firstSeen ? false : Math.random() > 0.5;
      },
      activity() {
        return this.showTypeIn ? TypeActivity : FlashCardActivity;
      },
      noHard() {
        return FIRST_RESPONSE_NO_HARD;
      },
      noOkay() {
        return FIRST_RESPONSE_NO_OKAY;
      },
      maybeNo() {
        return FIRST_RESPONSE_MAYBE_NO;
      },
      maybeYes() {
        return FIRST_RESPONSE_MAYBE_YES;
      },
      yesWrong() {
        return FIRST_RESPONSE_YES_WRONG;
      },
      yesKnowIt() {
        return FIRST_RESPONSE_YES_KNOW_IT;
      },
      yesBarelyKnowIt() {
        return FIRST_RESPONSE_YES_BARELY_KNOW_IT;
      },
      yesWontForget() {
        return FIRST_RESPONSE_YES_WONT_FORGET;
      },
      didntKnow() {
        return RESPONSE_DIDNT_KNOW;
      },
      justRight() {
        return RESPONSE_JUST_RIGHT;
      },
      hard() {
        return RESPONSE_HARD;
      },
      easy() {
        return RESPONSE_EASY;
      },
      stillStruggling() {
        return RESPONSE_STILL_STRUGGLING;
      },
      gotIt() {
        return RESPONSE_GOT_IT;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../vars';
  .test-item {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .first-seen {
    top: -22px;
    left: -22px;
    position: absolute;
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    &::before,
    &::after {
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 3px solid darken($green, 40);
    }
    &::before {
      top: 0;
      right: 0;
    }
    &::after {
      bottom: 0;
      left: 0;
    }
    span {
      position: absolute;
      display: block;
      width: 225px;
      padding: 15px 5px 15px 0;
      background-color: darken($green, 25);
      box-shadow: 0 5px 10px rgba(0,0,0,.1);
      color: #fff;
      font-size: 1rem;
      text-shadow: 0 1px 1px rgba(0,0,0,.2);
      text-transform: uppercase;
      text-align: center;
      right: -25px;
      top: 30px;
      transform: rotate(-45deg);
      letter-spacing: 0.1ch;
    }
  }

  .test-item-component {
    margin-top: 100px;
  }

  .flip-button {
    display: block;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    color: var(--body-text-color);
  }

  .know-it {
    .know-it-yes,
    .know-it-no {
      cursor: pointer;
    }
    > span:hover {
      color: white;
    }
  }

  .item-display {
    text-align: center;
    font-size: 3rem;
  }
  .item-display-back {
    text-align: center;
    font-size: 2rem;
  }
  .response-row {
    display: flex;
    justify-content: center;
  }
  .caption {
    text-align: center;
  }
  .response-row > * {
    padding: 1rem;
    margin: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .show-answer {
    text-align: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .canceling {
    margin-top: 3rem;
    font-size: 1.5rem;
    text-align: center;
    font-style: italic;
  }
</style>
