<template functional>
  <span :class="props.rating" :title="props.rating">
    <component
      :is="$options.getComponent()"
      :icon="['fas', $options.getIcon(props.rating)]"
      :rotation="$options.getRotation(props.rating)"
    />
  </span>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  import { faTriangle } from '@fortawesome/pro-solid-svg-icons/faTriangle';
  import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
  import { faDiamond } from '@fortawesome/pro-solid-svg-icons/faDiamond';

  library.add(faTriangle);
  library.add(faCircle);
  library.add(faDiamond);

  const ICONS = {
    'first-response-yes-wrong': 'triangle',
    'first-response-yes-barely-know-it': 'triangle',
    'first-response-yes-know-it': 'triangle',
    'first-response-yes-okay': 'triangle',
    'first-response-yes-wont-forget': 'triangle',
    'first-response-no-okay': 'triangle',
    'first-response-no-hard': 'triangle',
    'first-response-maybe-yes': 'diamond',
    'first-response-maybe-no': 'diamond',
  };

  const ICON_ROTATION = {
    'first-response-no-okay': 180,
    'first-response-no-hard': 180,
  };

  export default {
    props: {
      rating: {
        type: String,
        required: true,
      },
    },
    components: { FontAwesomeIcon },
    getIcon(rating) {
      return ICONS[rating] || 'circle';
    },
    getRotation(rating) {
      return ICON_ROTATION[rating] || null;
    },
    getComponent() {
      return FontAwesomeIcon;
    },
  };
</script>

<style lang="scss" scoped>
  span {
    margin-right: 2px;
  }
  span.first-response-yes-wrong {
    color: var(--first-response-yes-wrong-text-color);
  }
  span.first-response-no-hard {
    color: var(--first-response-no-hard-text-color);
  }
  span.still-struggling {
    color: var(--still-struggling-text-color);
  }
  span.didnt-know {
    color: var(--didnt-know-text-color);
  }
  span.first-response-yes-barely-know-it {
    color: var(--first-response-yes-barely-know-it-text-color);
  }
  span.first-response-yes-know-it {
    color: var(--first-response-yes-know-it-text-color);
  }
  span.first-response-no-okay {
    color: var(--first-response-no-okay-text-color);
  }
  span.first-response-maybe-yes {
    color: var(--first-response-maybe-yes-text-color);
  }
  span.first-response-maybe-no {
    color: var(--first-response-maybe-no-text-color);
  }
  span.got-it,
  span.just-right {
    color: var(--just-right-text-color);
  }
  span.hard {
    color: var(--hard-text-color);
  }
  span.first-response-yes-wont-forget {
    color: var(--first-response-yes-wont-forget-text-color);
  }
  span.easy {
    color: var(--easy-text-color);
  }
</style>
