import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import VueGtag from 'vue-gtag';
import MixpanelPlugin from './mixpanel-plugin';
import 'typeface-quicksand';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './main.scss';

Vue.config.productionTip = false;
Vue.config.performance = true;

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.COMMIT_REF,
    environment: process.env.CONTEXT,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        tracingOptions: {
          trackComponents: true,
        },
      }),
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
}, router);
Vue.use(MixpanelPlugin, {
  token: process.env.VUE_APP_MIXPANEL,
});

new Vue({
  store,
  render: (h) => h(App),
  router,
}).$mount('#app');
