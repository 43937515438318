<template>
  <div class="main-grid" :class="{ 'admin-mode': adminMode }" v-if="authed">
    <AppCard @logout="onLogout" :myself="myself" />

    <template v-if="adminMode">
      <MetricsCard />
      <UserListCard  />
      <SessionsCard />
      <SessionDetailCard v-if="selectedSession" :session="selectedSession" />
      <ItemsCard />
      <ItemCard v-if="selectedItem" :item="selectedItem" />
    </template>

    <template v-else>
      <MetricsCard kind="counts" />
      <MetricsCard kind="progress" />
      <TestCard />
    </template>
  </div>
</template>

<script>
  import MetricsCard from '../components/MetricsCard.vue';
  import AppCard from '../components/AppCard.vue';
  import ItemsCard from '../components/ItemsCard/ItemsCard.vue';
  import ItemCard from '../components/ItemCard/ItemCard.vue';
  import SessionDetailCard from '../components/SessionDetailCard/SessionDetailCard.vue';
  import SessionsCard from '../components/SessionsCard/SessionsCard.vue';
  import TestCard from '../components/TestCard/TestCard.vue';
  import UserListCard from '../components/UserListCard/UserListCard.vue';

  import { AUTH_LOGOUT } from '../constants';

  export default {
    components: {
      MetricsCard,
      AppCard,
      ItemsCard,
      ItemCard,
      SessionDetailCard,
      SessionsCard,
      TestCard,
      UserListCard,
    },
    methods: {
      onLogout() {
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          this.$gtag.event('logout');
          this.$mixpanel.track('Logout');
          this.$mixpanel.reset();
        });
      },
    },
    computed: {
      isStaff() {
        return this.$store.state.isStaff;
      },
      authed() {
        return this.$store.state.authToken !== '';
      },
      myself() {
        return this.$store.state.selectedUser === null;
      },
      adminMode() {
        return this.$store.state.adminMode;
      },
      selectedItem() {
        let item = null;
        if (this.$store.state.selectedItemId) {
          item = {
            ...this.$store.state.items[this.$store.state.selectedItemId],
            ...this.$store.state.userItems[this.$store.state.selectedItemId],
          };
        }
        return item;
      },
      selectedSession() {
        return this.$store.getters.selectedSession;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-grid {
    padding: 1rem;
    display: grid;
    height: calc(100vh - 2rem);
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 0.25fr 1fr 1fr 1fr;
    grid-gap: 1rem;

    &.admin-mode {
      background: #444;

      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
  }

  // Grids
  .main-grid::v-deep {
    .metrics-card {
      grid-column-start: 1;
      grid-row-start: 2;
      grid-row-end: span 3;
      overflow: auto;

      &.progress {
        grid-column-start: 3;
      }
    }
    .app-card {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;

      &.admin-mode {
        grid-column-end: 1;
      }
    }
    .user-list-card {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: span 1;
      overflow: auto;
    }
    .test-session-card {
      grid-column-start: 2;
      grid-row-start: 4;
    }
    .test-item-card {
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: span 2;
    }
    .sessions-card {
      grid-column-start: 3;
      grid-row-start: 1;
      grid-row-end: span 2;
      overflow: scroll;
    }
    .session-detail-card {
      grid-column-start: 3;
      grid-row-start: 3;
      grid-row-end: span 2;
      overflow: scroll;
    }
    .items-card {
      grid-column-start: 4;
      grid-row-start: 1;
      grid-row-end: span 2;
      overflow: scroll;
    }
    .item-card {
      grid-column-start: 4;
      grid-row-start: 3;
      grid-row-end: span 2;
    }
    .test-card {
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: span 3;
    }
  }
  @media (max-width: 800px) {
    .main-grid {
      height: unset;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
    .main-grid::v-deep {
      .metrics-card {
        grid-column-start: 1;
        grid-row-start: 5;
        grid-row-end: span 3;
      }
      .app-card {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
      }
      .metrics-card.progress {
        grid-column-start: 1;
        grid-row-start: 8;
        grid-row-end: span 3;
      }
      .test-card {
        grid-column-start: 1;
        grid-row-start: 2;
        grid-row-end: span 3;
      }
    }
  }
</style>
