<template>
  <AuthPage>
    <h1>Log in</h1>
    <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="model.email" placeholder="email" />
    <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="model.password" type="password"  placeholder="password" />
    <div class="auth-error" v-if="authError">{{ authError }}</div>
    <div class="form-actions">
      <button class="btn-login" @click="onLogin">Log in</button>
    </div>
    <div class="footer">
      Did you forget your password?  <router-link :to="{ name: 'password-reset' }">Reset Your Password</router-link>
    </div>
  </AuthPage>
</template>

<script>
  import { AUTH_LOGIN } from '../constants';
  import AuthPage from './AuthPage.vue';

  export default {
    components: { AuthPage },
    data() {
      return {
        model: {
          email: '',
          password: '',
        },
      };
    },
    watch: {
      authed: {
        immediate: true,
        handler() {
          if (this.authed) {
            this.model.email = '';
            this.model.password = '';
          }
        },
      },
    },
    methods: {
      onLogin() {
        const { email, password } = this.model;
        this.$store.dispatch(AUTH_LOGIN, { email, password })
          .then(() => {
            if (this.authed) {
              this.$gtag.config({ user_id: 'USER_ID' });
              this.$gtag.set({
                django_user_id: this.$store.state.userId,
              });
              this.$gtag.event('login');

              this.$mixpanel.identify(this.$store.state.email);
              this.$mixpanel.people.set({ $email: this.$store.state.email });
              this.$mixpanel.track('Login');
            }
          });
      },
    },
    computed: {
      authed() {
        return this.$store.state.authToken !== '';
      },
      authError() {
        return this.$store.state.authError;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../vars';
  .auth-error {
    font-size: 1rem;
    text-align: left;
    color: $red;
  }
</style>
