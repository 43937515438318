<template>
  <section :key="lang">
    <h3 v-if="title">
      {{ title }}
      <span v-if="closeable" class="close" @click="$emit('closed')">X</span>
    </h3>
    <slot />
  </section>
</template>

<script>
  export default {
    props: {
      title: String,
      closeable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      lang() {
        return this.$store.state.studyingLanguage;
      },
    },
  };
</script>

<style lang="scss" scoped>
  section {
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: rgba(black, 0.3);
  }
  h3 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: var(--section-h3-text-color);
    display: flex;
    justify-content: space-between;

    .close {
      font-size: 0.5rem;
      padding: 0.25rem 0.375rem;
      cursor: pointer;
      border-radius: 20px;
      background: var(--section-close-background-color);
      color: var(--section-close-text-color);
      &:hover {
        background: var(--section-close-hover-background-color);
        color: var(--section-close-hover-text-color);
      }
    }
  }
</style>
