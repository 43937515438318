<template functional>
  <span class="strength" :class="$options.getClass(props.strength)">{{ props.strength }}</span>
</template>

<script>
  export default {
    props: {
      strength: Number,
    },
    getClass(strength) {
      let c;
      if (strength === 0) {
        c = 'strength-unknown';
      }
      if (strength > 0 && strength < 1) {
        c = 'strength-very-weak';
      }
      if (strength >= 1 && strength < 2) {
        c = 'strength-weak';
      }
      if (strength >= 2 && strength < 3) {
        c = 'strength-just-ok';
      }
      if (strength >= 3 && strength < 4) {
        c = 'strength-good-enough';
      }
      if (strength >= 4 && strength < 5) {
        c = 'strength-good';
      }
      if (strength >= 5 && strength < 6) {
        c = 'strength-strong';
      }
      if (strength >= 6) {
        c = 'strength-very-strong';
      }
      if (strength === null) {
        c = 'strength-unseen';
      }
      return c;
    },
  };
</script>

<style lang="scss" scoped>
  .strength-unseen {
    color: #777787;
  }
  .strength-unknown {
    color: #FF4444;
  }
  .strength-very-weak {
    color: #FFB2B4;
  }
  .strength-weak {
    color: #AECCAE;
  }
  .strength-just-ok {
    color: #8FCC8F;
  }
  .strength-good-enough {
    color: #6FCC6F;
  }
  .strength-good {
    color: #52CC52;
  }
  .strength-strong {
    color: #33CC33;
  }
  .strength-very-strong {
    color: #00E600;
  }
</style>
