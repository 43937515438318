<template>
  <span class="button" :class="[kind, { block, active }]" @click.prevent="$emit('click')">
    <slot />
  </span>
</template>

<script>
  export default {
    props: {
      block: {
        type: Boolean,
        default: true,
      },
      kind: {
        type: String,
        default: 'default',
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button {
    display: inline-block;
    cursor: pointer;

    &.block {
      display: block;
      text-align: center;
      margin: 1rem;
      padding: 0.5rem;
    }
    &.default {
      color: var(--button-default-text-color);
      background: var(--button-default-background-color);
      &:hover {
        color: var(--button-default-hover-text-color);
        background: var(--button-default-hover-background-color);
      }
      &.active {
        color: var(--button-default-active-text-color);
        background: var(--button-default-active-background-color);
      }
    }
    &.success {
      color: var(--button-success-text-color);
      background: var(--button-success-background-color);
      &:hover {
        color: var(--button-success-hover-text-color);
        background: var(--button-success-hover-background-color);
      }
      &.active {
        color: var(--button-success-active-text-color);
        background: var(--button-success-active-background-color);
      }
    }
    &.primary {
      color: var(--button-primary-text-color);
      background: var(--button-primary-background-color);
      &:hover {
        color: var(--button-primary-hover-text-color);
        background: var(--button-primary-hover-background-color);
      }
      &.active {
        color: var(--button-primary-active-text-color);
        background: var(--button-primary-active-background-color);
      }
    }
    &.warning {
      color: var(--button-warning-text-color);
      background: var(--button-warning-background-color);
      &:hover {
        color: var(--button-warning-hover-text-color);
        background: var(--button-warning-hover-background-color);
      }
      &.active {
        color: var(--button-warning-active-text-color);
        background: var(--button-warning-active-background-color);
      }
    }
    &.danger {
      color: var(--button-danger-text-color);
      background: var(--button-danger-background-color);
      &:hover {
        color: var(--button-danger-hover-text-color);
        background: var(--button-danger-hover-background-color);
      }
      &.active {
        color: var(--button-danger-active-text-color);
        background: var(--button-danger-active-background-color);
      }
    }
  }
</style>
