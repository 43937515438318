<template>
  <div class="app">
    <router-view />
    <LoadingOverlay v-if="showLoader" />
  </div>
</template>

<script>
  import {
    FETCH_ITEMS,
    FETCH_USER_ITEMS,
    FETCH_SESSIONS,
    FETCH_PROFILE,
  } from './constants';
  import LoadingOverlay from './components/LoadingOverlay.vue';

  export default {
    components: { LoadingOverlay },
    data() {
      return {
        fetching: false,
      };
    },
    computed: {
      authed() {
        return this.$store.state.authToken !== '';
      },
      settingLanguage() {
        return this.$store.state.settingLanguage;
      },
      showLoader() {
        return this.fetching || this.settingLanguage;
      },
      selectedUser() {
        return this.$store.state.selectedUser;
      },
      selectedUserLang() {
        return this.$store.state.selectedUserLang;
      },
    },
    methods: {
      fetchData() {
        this.fetching = true;
        const a = this.$store.dispatch(FETCH_ITEMS);
        const b = this.$store.dispatch(FETCH_USER_ITEMS);
        const c = this.$store.dispatch(FETCH_SESSIONS);
        const d = this.$store.dispatch(FETCH_PROFILE);
        Promise.all([a, b, c, d]).then(() => {
          this.fetching = false;
        });
      },
    },
    watch: {
      selectedUser() {
        this.fetchData();
      },
      selectedUserLang() {
        this.fetchData();
      },
      authed: {
        immediate: true,
        handler() {
          if (this.authed) {
            this.fetchData();
            if (this.$route.name !== 'learn') {
              this.$router.push({ name: 'learn' });
            }
          } else if (this.$route.name === 'learn') {
            this.$router.push({ name: 'login' });
          }
        },
      },
    },
  };
</script>

<style lang="scss">
  body {
    margin: 0;
    background: var(--body-background-color);
    color: var(--body-text-color);

    margin: 0;
    height: calc(100vh - 2rem);
    // padding: 1rem;
    font-family: var(--main-font-family);
    font-size: 0.8rem;
  }

  table {
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
  }
  th {
    background: rgba(white, 0.1);
    padding: 2px;
  }
  td {
    padding: 2px;
  }
  tr:nth-child(2n-1) {
    background: rgba(white, 0.15);
  }
</style>
