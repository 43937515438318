<template>
  <div class="daily-stats">
    <StatsBoxRow class="top" :small="true" :flipped="true">
      <StatsBox class="overdue" :stat="overdueCount">overdue</StatsBox>
      <StatsBox class="due" :stat="dueCount">due</StatsBox>
      <StatsBox class="not-due" :stat="dueTomorrowCount">due tomorrow</StatsBox>
    </StatsBoxRow>
    <div class="stats-container"><slot /></div>
    <StatsBoxRow class="bottom" v-if="itemsSeenTodayCount > 0" :small="true" :flipped="true">
      <StatsBox class="seen" :stat="itemsSeenTodayCount">items seen today</StatsBox>
      <StatsBox class="reviewed" :stat="reviewedTodayCount">previously seen</StatsBox>
      <StatsBox class="never-seen" :stat="neverSeenCount">never seen</StatsBox>
      <StatsBox class="remembered" :stat="rememberedTodayCount">remembered</StatsBox>
      <StatsBox class="forgot" :stat="forgotCount">forgot</StatsBox>
      <StatsBox class="known" :stat="alreadyKnownCount">already known</StatsBox>
      <StatsBox class="new" :stat="newTodayCount">new</StatsBox>
    </StatsBoxRow>
  </div>
</template>

<script>
  import StatsBoxRow from '../StatsBoxRow.vue';
  import StatsBox from '../StatsBox.vue';
  import { FIRST_RESPONSE_ALREADY_KNOWN, RESPONSE_DIDNT_KNOW } from '../../constants';

  export default {
    components: {
      StatsBoxRow,
      StatsBox,
    },
    computed: {
      dueCount() {
        return this.$store.getters.dueItems.length;
      },
      overdueCount() {
        return this.$store.getters.overdueItems.length;
      },
      dueTomorrowCount() {
        return this.$store.getters.dueTomorrowItems.length;
      },
      itemsSeenTodayCount() {
        const { seenItems, showings } = this.$store.getters;
        return seenItems
          .filter(item => showings
            .filter(showing => showing.item === item.id)
            .filter(showing => showing.when === this.$store.state.day).length > 0).length;
      },
      reviewedTodayCount() {
        // how many cards seen today had been seen before
        return this.$store.getters.repeatedToday.length;
      },
      rememberedTodayCount() {
        return this.$store.getters.repeatedToday
          .filter(item => {
            const itemShowingsToday = this.$store.getters.todaysCompletedShowings.filter(s => s.item === item.id);
            return itemShowingsToday.filter(s => s.response === RESPONSE_DIDNT_KNOW).length === 0;
          }).length;
      },
      forgotCount() {
        return this.$store.getters.forgotToday.length;
      },
      newTodayCount() {
        return this.$store.getters.newItemsUnknownSeenToday.length;
      },
      neverSeenCount() {
        return this.$store.getters.itemsFirstSeenToday.length;
      },
      alreadyKnownCount() {
        // never seen until today but already known
        return this.$store.getters.itemsFirstSeenToday
          .filter(item => FIRST_RESPONSE_ALREADY_KNOWN.indexOf(item.firstResponse.response) > -1)
          .length;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../vars';
  .overdue {
    color: $overdue;
  }
  .due {
    color: $due;
  }
  .not-due {
    color: $not-due;
  }

  .stats-container {
    margin: 80px 0;
  }

  .top::v-deep {
    margin: 0 auto;
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .bottom {
    margin: 20px auto;
    max-width: 600px;
  }
  .bottom::v-deep {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    .seen {
      grid-column: span 4;
    }
    .reviewed,
    .never-seen {
      grid-column: span 2;
    }
    .seen {
      color: inherit;
    }
    .never-seen {
      color: inherit;
    }
    .remembered {
      color: inherit;
    }
    .forgot {
      color: inherit;
    }
    .known {
      color: inherit;
    }
    .new {
      color: inherit;
    }
  }
</style>
