import Vue from 'vue';
import * as mixpanel from 'mixpanel-browser';

const MixpanelPlugin = {
  install(vue, { token, config }) {
    console.log('token', token);
    if (token) {
      Vue.prototype.$mixpanel = mixpanel;
      Vue.prototype.$mixpanel.init(token);
      if (config) {
        Vue.prototype.$mixpanel.set_config(config);
      }
    } else {
      Vue.prototype.$mixpanel = {
        time_event: () => {},
        track: () => {},
        reset: () => {},
        people: {
          set: () => {},
        },
        identify: () => {},
      };
    }
  },
};

export default MixpanelPlugin;
