<template>
  <div class="card-count-box">
    <span class="num"><slot name="num">{{ stat }}</slot></span>
    <span class="legend"><slot /></span>
  </div>
</template>

<script>
  export default {
    props: ['stat'],
  };
</script>

<style lang="scss" scoped>
  .card-count-box {
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0.2rem;
    background: rgba(white, 0.1);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .num {
    text-align: center;
    font-size: 2rem;
  }

  .legend {
    text-align: center;
    font-size: 0.8rem;
    color: var(--stat-box-legend-text-color);
  }

  .flipped {
    .card-count-box {
      flex-direction: column-reverse;
    }
  }

  .small {
    .card-count-box {
      padding: 0.3rem;
    }
    .num {
      font-size: 1rem;
    }
    .legend {
      font-size: 0.7rem;
    }
  }
</style>
