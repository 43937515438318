<template>
  <tr>
    <th>{{ item.word }}</th>
    <td class="history">
      <RatingBall v-for="(rating, index) in item.ratings" :key="index" :rating="rating" />
    </td>
    <td>{{ item.timesSeen }}</td>
    <td>{{ item.streak }}</td>
    <td>{{ item.nextDue }}</td>
  </tr>
</template>

<script>
  import RatingBall from '../RatingBall.vue';

  export default {
    props: ['item'],
    components: { RatingBall },
  };
</script>

<style lang="scss" scoped>
  td.history {
    text-align: left;
  }
</style>
