<template>
  <div class="session-stats">
    <h3>Session Summary</h3>
    <table>
      <tr v-for="(item, index) in items" :key="index" :item="item">
        <td class="history">
          <RatingBall v-for="(rating, index) in item.ratings" :key="index" :rating="rating" />
        </td>
        <td>{{ item.word }}</td>
        <td>{{ item.gloss }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import {
    RESPONSE_DIDNT_KNOW,
    RESPONSE_HARD,
    RESPONSE_JUST_RIGHT,
    RESPONSE_EASY,
    FIRST_RESPONSE_YES_WRONG,
    FIRST_RESPONSE_NO_HARD,
    FIRST_RESPONSE_MAYBE_YES,
    FIRST_RESPONSE_MAYBE_NO,
    RESPONSE_STILL_STRUGGLING,
    FIRST_RESPONSE_YES_KNOW_IT,
    FIRST_RESPONSE_YES_BARELY_KNOW_IT,
    FIRST_RESPONSE_NO_OKAY,
    FIRST_RESPONSE_YES_WONT_FORGET,
  } from '../../constants';

  import RatingBall from '../RatingBall.vue';

  export default {
    components: {
      RatingBall,
    },
    computed: {
      items() {
        return this.$store.getters.items
          .filter(item => this.showings.filter(s => s.item === item.id).length > 0)
          .map(item => ({
            ...item,
            ratings: [...this.showings.filter(s => s.item === item.id).map(s => s.response)],
          }));
      },
      showings() {
        return this.$store.state.showingsOrder
          .map(showingId => this.$store.state.showings[showingId])
          .filter(showing => showing.session === this.$store.state.activeSessionId);
      },
      itemsCount() {
        return [...new Set(this.showings.map(showing => showing.item))].length;
      },
      yesWrongCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_YES_WRONG).length;
      },
      noHardCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_NO_HARD).length;
      },
      stillStrugglingCount() {
        return this.showings.filter(showing => showing.response === RESPONSE_STILL_STRUGGLING).length;
      },
      barelyKnowItCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_YES_BARELY_KNOW_IT).length;
      },
      knowItCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_YES_KNOW_IT).length;
      },
      maybeNoCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_MAYBE_NO).length;
      },
      maybeYesCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_MAYBE_YES).length;
      },
      noOkayCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_NO_OKAY).length;
      },
      wontForgetCount() {
        return this.showings.filter(showing => showing.response === FIRST_RESPONSE_YES_WONT_FORGET).length;
      },
      didntKnowCount() {
        return this.showings.filter(showing => showing.response === RESPONSE_DIDNT_KNOW).length;
      },
      hardCount() {
        return this.showings.filter(showing => showing.response === RESPONSE_HARD).length;
      },
      justRightCount() {
        return this.showings.filter(showing => showing.response === RESPONSE_JUST_RIGHT).length;
      },
      easyCount() {
        return this.showings.filter(showing => showing.response === RESPONSE_EASY).length;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .first-response-yes-wrong {
    color: var(--first-response-yes-wrong-text-color);
  }
  .first-response-no-hard {
    color: var(--first-response-no-hard-text-color);
  }
  .still-struggling {
    color: var(--still-struggling-text-color);
  }
  .first-response-yes-barely-know-it {
    color: var(--first-response-yes-barely-know-it-text-color);
  }
  .first-response-yes-know-it {
    color: var(--first-response-yes-know-it-text-color);
  }
  .first-response-maybe-no {
    color: var(--first-response-maybe-no-text-color);
  }
  .first-response-maybe-yes {
    color: var(--first-response-maybe-yes-text-color);
  }
  .first-response-no-okay {
    color: var(--first-response-no-okay-text-color);
  }
  .first-response-yes-wont-forget {
    color: var(--first-response-yes-wont-forget-text-color);
  }
  .didnt-know {
    color: var(--didnt-know-text-color);
  }
  .didnt-know {
    color: var(--didnt-know-text-color);
  }
  .just-right {
    color: var(--just-right-text-color);
  }
  .easy {
    color: var(--easy-text-color);
  }
  td, th {
    text-align: left;
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
  table {
    margin-bottom: 20px;
  }
</style>
