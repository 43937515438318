<template>
  <Card class="card user-list-card" title="Users">
    <table>
      <thead>
        <tr>
          <th>User</th>
          <th>Languages</th>
          <th>Sessions</th>
          <th>Feedback</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.email }}</td>
          <td class="lang">
            <span v-for="lang in user.availableLanguages" :key="lang" :class="{ active: lang === user.studyingLanguage, selected: selected(user, lang) }" @click.prevent="onSelectUser(user, lang)">
              {{ lang }}
            </span>
          </td>
          <td>{{ user.sessions }}</td>
          <td>{{ user.feedbacks }}</td>
        </tr>
      </tbody>
    </table>
  </Card>
</template>

<script>
  import api from '../../api';
  import { SELECT_USER } from '../../constants';
  import Card from '../Card.vue';

  export default {
    components: { Card },
    data() {
      return {
        users: [],
      };
    },
    methods: {
      onSelectUser(user, lang) {
        this.$store.dispatch(SELECT_USER, { user, lang });
      },
      selected(user, lang) {
        return this.$store.state.selectedUser === user.id && this.$store.state.selectedUserLang === lang;
      },
    },
    computed: {
      isStaff() {
        return this.$store.state.isStaff;
      },
    },
    watch: {
      isStaff: {
        immediate: true,
        handler() {
          if (this.isStaff) {
            api.staffGetUserStats().then(data => {
              this.users = data.data;
            });
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../vars';

  table {
    margin-top: 10px;
  }
  tr td:first-child {
    text-align: left;
  }
  td.lang span {
    cursor: pointer;
  }
  td.lang span.active {
    font-weight: 600;
    color: white;
  }
  td.lang span.selected {
    color: $green;
  }
  td.lang span:not(.selected):hover {
    color: rgba($green, 0.5);
  }
  tr:hover {
    background: black;
  }
</style>
