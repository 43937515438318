<template>
  <tr class="history" @click.prevent="onSelect">
    <th>{{ session.name }}</th>
    <td>Day {{ session.when }}</td>
    <td>{{ done.length }}</td>
    <td>{{ showings.length }}</td>
    <td class="didnt-know">{{ didntKnow.length }}</td>
    <td class="hard">{{ hard.length }}</td>
    <td class="just-right">{{ justRight.length }}</td>
    <td class="easy">{{ easy.length }}</td>
  </tr>
</template>

<script>
  import { SELECT_SESSION, ACTION_PUT_BACK } from '../../constants';

  export default {
    props: ['session'],
    methods: {
      onSelect() {
        this.$store.dispatch(SELECT_SESSION, { id: this.session.id });
      },
    },
    computed: {
      didntKnow() {
        return this.showings.filter(showing => showing.response === 'didnt-know');
      },
      hard() {
        return this.showings.filter(showing => showing.response === 'hard');
      },
      justRight() {
        return this.showings.filter(showing => showing.response === 'just-right');
      },
      easy() {
        return this.showings.filter(showing => showing.response === 'easy');
      },
      showings() {
        return this.session.showings;
      },
      done() {
        return this.showings
          .filter(showing => showing.action !== ACTION_PUT_BACK);
      },
    },
  };
</script>

<style lang="scss" scoped>
  tr.history {
    cursor: pointer;
  }
</style>
