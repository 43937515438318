<template>
  <div class="flash-card-activity">
    <div class="item-display">{{ item.word }}</div>
    <template v-if="flipped">
      <div class="item-display-back">{{ item.gloss }}</div>

      <div class="response-row" v-if="seenToday">
        <RatingButton :kind="stillStruggling" @responded="onRespond" v-if="!lastItem">still struggling</RatingButton>
        <RatingButton :kind="gotIt" @responded="onRespond">got it</RatingButton>
      </div>
      <div class="response-row" v-else-if="firstSeen && knowIt === 'no'">
        <RatingButton :kind="noHard" @responded="onRespond">seems hard</RatingButton>
        <RatingButton :kind="noOkay" @responded="onRespond">seems ok</RatingButton>
      </div>
      <div class="response-row" v-else-if="firstSeen && knowIt === 'maybe'">
        <RatingButton :kind="maybeNo" @responded="onRespond">didn't know it</RatingButton>
        <RatingButton :kind="maybeYes" @responded="onRespond">knew it</RatingButton>
      </div>
      <div class="response-row know-it-yet" v-else-if="firstSeen && knowIt === 'yes'">
        <RatingButton :kind="yesWrong" @responded="onRespond">got it wrong</RatingButton>
        <RatingButton :kind="yesBarelyKnowIt" @responded="onRespond">barely <span class="nw">know it</span></RatingButton>
        <RatingButton :kind="yesKnowIt" @responded="onRespond">yes, <span class="nw">know it</span></RatingButton>
        <RatingButton :kind="yesWontForget" @responded="onRespond">won't forget</RatingButton>
      </div>
      <div class="response-row" v-else>
        <RatingButton :kind="didntKnow" @responded="onRespond">forgot</RatingButton>
        <RatingButton :kind="hard" @responded="onRespond">hard</RatingButton>
        <RatingButton :kind="justRight" @responded="onRespond">just right</RatingButton>
        <RatingButton :kind="easy" @responded="onRespond">easy</RatingButton>
      </div>
      <ItemFeedbackForm :item="item" />
    </template>
    <slot v-else-if="knowIt === null && firstSeen && !seenToday" />
    <div class="flip-container" v-else>
      <VButton @click="onFlip">REVEAL GLOSS</VButton>
    </div>
  </div>
</template>

<script>
  import {
    FIRST_RESPONSE_YES_WRONG,
    FIRST_RESPONSE_YES_KNOW_IT,
    FIRST_RESPONSE_YES_BARELY_KNOW_IT,
    FIRST_RESPONSE_YES_WONT_FORGET,
    FIRST_RESPONSE_MAYBE_YES,
    FIRST_RESPONSE_MAYBE_NO,
    FIRST_RESPONSE_NO_HARD,
    FIRST_RESPONSE_NO_OKAY,
    RESPONSE_DIDNT_KNOW,
    RESPONSE_JUST_RIGHT,
    RESPONSE_HARD,
    RESPONSE_EASY,
    RESPONSE_STILL_STRUGGLING,
    RESPONSE_GOT_IT,
  } from '../../constants';

  import RatingButton from './RatingButton.vue';
  import ItemFeedbackForm from './ItemFeedbackForm.vue';
  import VButton from '../VButton.vue';

  export default {
    props: ['item', 'knowIt', 'lastItem', 'showings'],
    data() {
      return {
        shownAt: null,
        revealedAt: null,
        flipped: this.knowIt !== null,
      };
    },
    watch: {
      knowIt: {
        immediate: true,
        handler() {
          this.flipped = this.knowIt !== null;
        },
      },
    },
    components: {
      RatingButton,
      ItemFeedbackForm,
      VButton,
    },
    mounted() {
      this.shownAt = new Date();
    },
    methods: {
      onFlip() {
        this.flipped = true;
        this.revealedAt = new Date();
      },
      onRespond(response) {
        this.$emit('responded', {
          response,
          input: '',
          activityType: 'flash-card',
          shownAt: this.shownAt,
          revealedAt: this.revealedAt,
          cb: () => { this.flipped = false; },
        });
      },
    },
    computed: {
      firstSeen() {
        return this.item.lastSeen === null;
      },
      seenToday() {
        return this.showings.length > 0 || (this.item.lastSeen && this.item.lastSeen === this.$store.state.day);
      },
      noHard() {
        return FIRST_RESPONSE_NO_HARD;
      },
      noOkay() {
        return FIRST_RESPONSE_NO_OKAY;
      },
      maybeNo() {
        return FIRST_RESPONSE_MAYBE_NO;
      },
      maybeYes() {
        return FIRST_RESPONSE_MAYBE_YES;
      },
      yesWrong() {
        return FIRST_RESPONSE_YES_WRONG;
      },
      yesKnowIt() {
        return FIRST_RESPONSE_YES_KNOW_IT;
      },
      yesBarelyKnowIt() {
        return FIRST_RESPONSE_YES_BARELY_KNOW_IT;
      },
      yesWontForget() {
        return FIRST_RESPONSE_YES_WONT_FORGET;
      },
      didntKnow() {
        return RESPONSE_DIDNT_KNOW;
      },
      justRight() {
        return RESPONSE_JUST_RIGHT;
      },
      hard() {
        return RESPONSE_HARD;
      },
      easy() {
        return RESPONSE_EASY;
      },
      stillStruggling() {
        return RESPONSE_STILL_STRUGGLING;
      },
      gotIt() {
        return RESPONSE_GOT_IT;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .test-item-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .flip-container {
    margin: 3rem;
    text-align: center;
    font-size: 1.2rem;

    &::v-deep a.v-button {
      margin-top: 2rem;
      max-width: 200px;
    }
  }

  .know-it {
    .know-it-yes,
    .know-it-no {
      cursor: pointer;
    }
  }

  .nw {
    white-space: nowrap;
  }

  .item-display {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 20px;
  }
  .item-display-back {
    text-align: center;
    font-size: 2rem;
    margin-top: 3.5rem;
    margin-bottom: 40px;
  }
  .response-row {
    display: flex;
    justify-content: center;

    &.know-it-yet {
      text-align: center;
    }
  }
  .caption {
    text-align: center;
  }
  .response-row > * {
    padding: 1rem;
    margin: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
</style>
