import axios from 'axios';

const prodApiURL = process.env.VUE_APP_BACKEND_API_URL || 'api/';
const baseURL = process.env.NODE_ENV === 'production'
  ? prodApiURL
  : 'http://localhost:8000/api/';

const HTTP = axios.create({ baseURL });

const setAuthHeader = token => {
  HTTP.defaults.headers.common.Authorization = `Token ${token}`;
};
const removeAuthHeader = () => delete HTTP.defaults.headers.common.Authorization;

export default {
  login: (email, password) => HTTP.post('auth/', { email, password }).then(r => r.data),
  resetPassword: email => HTTP.post('account/password-reset/', { email }).then(r => r.data),
  setPassword: (password, token) => HTTP.post(`account/password-reset/${token}/`, { password }).then(r => r.data),

  getProfile: () => HTTP.get('profile/').then(r => r.data),
  getItems: (userId, lang) => {
    const qs = userId ? `?u=${userId}&l=${lang}` : '';
    return HTTP.get(`items/${qs}`).then(r => r.data);
  },
  getUserItems: (userId, lang) => {
    const qs = userId ? `?u=${userId}&l=${lang}` : '';
    return HTTP.get(`user-items/${qs}`).then(r => r.data);
  },
  getSessions: (userId, lang) => {
    const qs = userId ? `?u=${userId}&l=${lang}` : '';
    return HTTP.get(`sessions/${qs}`).then(r => r.data);
  },

  leaveFeedback: (id, comments) => HTTP.post(`items/${id}/feedback/`, { comments }).then(r => r.data),
  setLanguage: language => HTTP.post('profile/', { language }).then(r => r.data),
  createSession: (when, items) => HTTP.post('sessions/', { when, items }).then(r => r.data),
  endSession: (id, userItems, showings) => HTTP.post(`sessions/${id}/end/`, { userItems, showings }).then(r => r.data),
  cancelSession: (id) => HTTP.post(`sessions/${id}/cancel/`).then(r => r.data),

  staffGetUserStats: () => HTTP.get('staff/users/').then(r => r.data),

  setAuthHeader,
  removeAuthHeader,
};
