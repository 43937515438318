<template>
  <div class="word-list-progress">
    <span class="strength-very-strong" :style="{ width: `${veryStrong}%`}"></span>
    <span class="strength-strong" :style="{ width: `${strong}%`}"></span>
    <span class="strength-good" :style="{ width: `${good}%`}"></span>
    <span class="strength-good-enough" :style="{ width: `${goodEnough}%`}"></span>
    <span class="strength-just-ok" :style="{ width: `${justOk}%`}"></span>
    <span class="strength-weak" :style="{ width: `${weak}%`}"></span>
    <span class="strength-very-weak" :style="{ width: `${veryWeak}%`}"></span>
    <span class="strength-unknown" :style="{ width: `${unknown}%`}"></span>
    <span class="strength-unseen" :style="{ width: `${unseen}%`}"></span>
  </div>
</template>

<script>
  import histograms from '../histograms';

  export default {
    props: {
      items: Array,
    },
    computed: {
      itemObjs() {
        return this.items.map(itemId => ({
          ...this.$store.state.items[itemId],
          ...this.$store.state.userItems[itemId],
        }));
      },
      counts() {
        return histograms.strengthCounts(this.itemObjs);
      },
      total() {
        return Object.values(this.counts).reduce((s, c) => s + c, 0);
      },
      unknown() {
        return (this.counts['strength-unknown'] / this.total) * 100;
      },
      veryWeak() {
        return (this.counts['strength-very-weak'] / this.total) * 100;
      },
      weak() {
        return (this.counts['strength-weak'] / this.total) * 100;
      },
      justOk() {
        return (this.counts['strength-just-ok'] / this.total) * 100;
      },
      goodEnough() {
        return (this.counts['strength-good-enough'] / this.total) * 100;
      },
      good() {
        return (this.counts['strength-good'] / this.total) * 100;
      },
      strong() {
        return (this.counts['strength-strong'] / this.total) * 100;
      },
      veryStrong() {
        return (this.counts['strength-very-strong'] / this.total) * 100;
      },
      unseen() {
        return (this.counts['strength-unseen'] / this.total) * 100;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .word-list-progress {
    span {
      display: inline-block;
      height: 10px;
    }
  }
  .strength-unseen {
    background-color: #777787;
  }
  .strength-unknown {
    background-color: #FF4444;
  }
  .strength-very-weak {
    background-color: #FFB2B4;
  }
  .strength-weak {
    background-color: #AECCAE;
  }
  .strength-just-ok {
    background-color: #8FCC8F;
  }
  .strength-good-enough {
    background-color: #6FCC6F;
  }
  .strength-good {
    background-color: #52CC52;
  }
  .strength-strong {
    background-color: #33CC33;
  }
  .strength-very-strong {
    background-color: #00E600;
  }
</style>
