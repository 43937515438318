<template>
  <Card class="card sessions-card" title="Sessions">
    <table>
      <thead>
        <tr>
          <th>Session</th>
          <th>When</th>
          <th>Item Count</th>
          <th>Showings</th>
          <th>Forgot</th>
          <th>Hard</th>
          <th>Just Right</th>
          <th>Easy</th>
        </tr>
      </thead>
      <tbody>
        <ItemRow v-for="session in sessions" :key="session.id" :session="session" />
      </tbody>
    </table>
  </Card>
</template>

<script>
  import Card from '../Card.vue';

  import ItemRow from './ItemRow.vue';

  export default {
    components: { Card, ItemRow },
    computed: {
      sessions() {
        return this.$store.getters.sessions;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .didnt-know {
    color: var(--didnt-know-text-color);
  }
  .just-right {
    color: var(--just-right-text-color);
  }
  .hard {
    color: var(--hard-text-color);
  }
  .easy {
    color: var(--easy-text-color);
  }
</style>
