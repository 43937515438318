<template>
  <Card class="card session-detail-card" :title="title" :closeable="true" @closed="onClose">
    <StatsBoxRow :small="true" :flipped="true">
      <StatsBox :stat="day">when</StatsBox>
      <StatsBox :stat="items.length">item count</StatsBox>
      <StatsBox :stat="sessionShowings.length">showings</StatsBox>
      <StatsBox :stat="stillStruggling.length" class="still-struggling">struggling</StatsBox>
    </StatsBoxRow>
    <StatsBoxRow :small="true" :flipped="true">
      <StatsBox :stat="noHard.length" class="first-response-no-hard">no, hard</StatsBox>
      <StatsBox :stat="noOkay.length" class="first-response-no-okay">no, okay</StatsBox>
    </StatsBoxRow>
    <StatsBoxRow :small="true" :flipped="true">
      <StatsBox :stat="maybeNo.length" class="first-response-maybe-no">maybe, no</StatsBox>
      <StatsBox :stat="maybeYes.length" class="first-response-maybe-yes">maybe, yes</StatsBox>
    </StatsBoxRow>
    <StatsBoxRow :small="true" :flipped="true">
      <StatsBox :stat="yesWrong.length" class="first-response-yes-wrong">yes, wrong</StatsBox>
      <StatsBox :stat="barelyKnowIt.length" class="first-response-yes-barely-know-it">barely know it</StatsBox>
      <StatsBox :stat="knowIt.length" class="first-response-yes-know-it">know it</StatsBox>
      <StatsBox :stat="wontForget.length" class="first-response-yes-wont-forget">won't forget</StatsBox>
    </StatsBoxRow>
    <StatsBoxRow :small="true" :flipped="true">
      <StatsBox :stat="didntKnow.length" class="didnt-know">forgot</StatsBox>
      <StatsBox :stat="hard.length" class="hard">hard</StatsBox>
      <StatsBox :stat="justRight.length" class="just-right">just right</StatsBox>
      <StatsBox :stat="easy.length" class="easy">easy</StatsBox>
    </StatsBoxRow>

    <h4>Items</h4>

    <table>
      <tr>
        <th>Item</th>
        <th>History</th>
        <th>Times Seen</th>
        <th>Streak</th>
        <th>Due Date Set</th>
      </tr>
      <ItemRow v-for="(item, index) in items" :key="index" :item="item" />
    </table>

    <h4>Showings</h4>

    <table>
      <tr>
        <th>ID</th>
        <th>Item</th>
        <th>Response</th>
        <th>Activity</th>
        <th>Action</th>
      </tr>
      <ShowingRow v-for="(showing, index) in sessionShowings" :key="index" :showing="showing" />
    </table>
  </Card>
</template>

<script>
  import Card from '../Card.vue';
  import StatsBoxRow from '../StatsBoxRow.vue';
  import StatsBox from '../StatsBox.vue';

  import ItemRow from './ItemRow.vue';
  import ShowingRow from './ShowingRow.vue';
  import algos from '../../algos';
  import {
    SELECT_SESSION,
    RESPONSE_DIDNT_KNOW,
    RESPONSE_HARD,
    RESPONSE_JUST_RIGHT,
    RESPONSE_EASY,
    FIRST_RESPONSE_MAYBE_YES,
    FIRST_RESPONSE_MAYBE_NO,
    FIRST_RESPONSE_YES_WRONG,
    FIRST_RESPONSE_NO_HARD,
    RESPONSE_STILL_STRUGGLING,
    FIRST_RESPONSE_YES_KNOW_IT,
    FIRST_RESPONSE_YES_BARELY_KNOW_IT,
    FIRST_RESPONSE_NO_OKAY,
    FIRST_RESPONSE_YES_WONT_FORGET,
  } from '../../constants';

  export default {
    props: ['session'],
    components: {
      Card,
      StatsBoxRow,
      StatsBox,
      ItemRow,
      ShowingRow,
    },
    methods: {
      onClose() {
        this.$store.dispatch(SELECT_SESSION, { id: null });
      },
    },
    computed: {
      title() {
        return `Session Detail: ${this.session.name}`;
      },
      day() {
        return `day ${this.session.when}`;
      },
      showings() {
        return this.$store.getters.showings;
      },
      items() {
        const dueMap = this.session.sessionItems.reduce((map, obj) => ({
          ...map,
          [obj.item]: obj.nextDue,
        }), {});
        const itemsMap = this.sessionShowings.reduce((map, obj) => {
          const item = this.$store.state.items[obj.item];
          if (!map[obj.item]) {
            map[obj.item] = {
              id: obj.item,
              name: item.word,
              word: item.word,
              gloss: item.gloss,
              ratings: [obj.response],
              timesSeen: 1,
              streak: algos.calculateStreak(obj.response, 0),
              nextDue: dueMap[item.id],
            };
          } else {
            map[obj.item].timesSeen += 1;
            const { response, streak } = map[obj.item];
            map[obj.item].streak = algos.calculateStreak(response, streak);
            map[obj.item].ratings.push(obj.response);
            map[obj.item].nextDue = dueMap[obj.item];
          }
          return map;
        }, {});
        return Object.keys(itemsMap).map(id => itemsMap[id]);
      },
      didntKnow() {
        return this.sessionShowings.filter(showing => showing.response === RESPONSE_DIDNT_KNOW);
      },
      hard() {
        return this.sessionShowings.filter(showing => showing.response === RESPONSE_HARD);
      },
      justRight() {
        return this.sessionShowings.filter(showing => showing.response === RESPONSE_JUST_RIGHT);
      },
      easy() {
        return this.sessionShowings.filter(showing => showing.response === RESPONSE_EASY);
      },
      yesWrong() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_YES_WRONG);
      },
      noHard() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_NO_HARD);
      },
      stillStruggling() {
        return this.sessionShowings.filter(showing => showing.response === RESPONSE_STILL_STRUGGLING);
      },
      knowIt() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_YES_KNOW_IT);
      },
      barelyKnowIt() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_YES_BARELY_KNOW_IT);
      },
      noOkay() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_NO_OKAY);
      },
      wontForget() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_YES_WONT_FORGET);
      },
      maybeNo() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_MAYBE_NO);
      },
      maybeYes() {
        return this.sessionShowings.filter(showing => showing.response === FIRST_RESPONSE_MAYBE_YES);
      },
      sessionShowings() {
        return this.showings.filter(showing => showing.session === this.session.id);
      },
    },
  };
</script>

<style lang="scss" scoped>
  td.history {
    text-align: left;
  }

  .first-response-yes-wrong {
    color: var(--first-response-yes-wrong-text-color);
  }
  .first-response-no-hard {
    color: var(--first-response-no-hard-text-color);
  }
  .still-struggling {
    color: var(--still-struggling-text-color);
  }
  .didnt-know {
    color: var(--didnt-know-text-color);
  }
  .first-response-yes-know-it {
    color: var(--first-response-yes-know-it-text-color);
  }
  .first-response-yes-barely-know-it {
    color: var(--first-response-yes-barely-know-it-text-color);
  }
  .first-response-maybe-no {
    color: var(--first-response-maybe-no-text-color);
  }
  .first-response-maybe-yes {
    color: var(--first-response-maybe-yes-text-color);
  }
  .first-response-no-okay {
    color: var(--first-response-no-okay-text-color);
  }
  .just-right {
    color: var(--just-right-text-color);
  }
  .hard {
    color: var(--hard-text-color);
  }
  .first-response-yes-wont-forget {
    color: var(--first-response-yes-wont-forget-text-color);
  }
  .easy {
    color: var(--easy-text-color);
  }
</style>
