<template>
  <span @click.prevent="$emit('click', expand)">
    <font-awesome-icon :icon="['fas', icon]" :rotation="rotation" />
  </span>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';

  library.add(faCaretRight);

  export default {
    props: {
      expand: {
        type: Boolean,
        required: true,
      },
    },
    components: { FontAwesomeIcon },
    computed: {
      icon() {
        return 'caret-right';
      },
      rotation() {
        return this.expand ? 90 : null;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
