<template>
  <AuthPage v-if="resetRequested">
    <h1>Check Email</h1>
    <p>You have been sent an email with a link for resetting your password.</p>
  </AuthPage>
  <AuthPage v-else-if="passwordToken">
    <h1>Set Password</h1>
    <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="model.password" type="password" placeholder="password" />
    <div class="form-actions">
      <button class="btn-login" @click="onSetPassword">Set Password</button>
    </div>
  </AuthPage>
  <AuthPage v-else>
    <h1>Reset Password</h1>
    <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="model.email" type="text" placeholder="email address" />
    <div class="form-actions">
      <button class="btn-login" @click="onResetPassword">Reset Password</button>
    </div>
    <div class="footer">
      Change your mind?  <router-link :to="{ name: 'login' }">Go back to login</router-link>
    </div>
  </AuthPage>
</template>

<script>
  import { AUTH_PASSWORD_RESET_CONFIRM } from '../constants';
  import api from '../api';
  import AuthPage from './AuthPage.vue';

  export default {
    components: { AuthPage },
    data() {
      return {
        model: {
          email: '',
          password: '',
        },
        resetRequested: false,
      };
    },
    methods: {
      onSetPassword() {
        const { password } = this.model;
        this.$store.dispatch(AUTH_PASSWORD_RESET_CONFIRM, { password, token: this.passwordToken }).then(() => {
          this.model.password = '';
        });
      },
      onResetPassword() {
        api.resetPassword(this.model.email).then(() => {
          this.resetRequested = true;
          this.model.email = '';
        });
      },
    },
    watch: {
      authError() {
        if (this.authError) {
          this.$router.push({ name: 'login' });
        }
      },
    },
    computed: {
      passwordToken() {
        return this.$route.params.token;
      },
      authError() {
        return this.$store.state.authError;
      },
    },
  };
</script>

<style lang="scss" scoped>
  p {
    font-size: 1.5rem;
    font-weight: 300;
  }
</style>
