<template>
  <div class="processing" v-if="processing">
    Calculating results...
  </div>
  <div class="session-close-button" v-else>
    <ActionButton @click="onEnd" :block="true" kind="success">Save Results</ActionButton>
  </div>
</template>

<script>
  import { END_SESSION } from '../../constants';
  import ActionButton from '../ActionButton.vue';

  export default {
    components: { ActionButton },
    data() {
      return {
        processing: false,
      };
    },
    methods: {
      onEnd() {
        this.processing = true;
        this.$store.dispatch(END_SESSION).then(() => {
          this.$gtag.event('close_session');
          this.$mixpanel.track('EndSession');
          this.processing = false;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../vars';
  .processing {
    margin: 1rem;
    padding: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
    font-style: italic;
  }
  .session-close-button {
    font-size: 1.5rem;
    margin: 80px 0;
  }
</style>
