<template>
  <tr>
    <th>{{ showing.name.replace('Showing ', '') }}</th>
    <td>{{ item.word }}</td>
    <td class="history"><span :class="showing.rating">{{ showing.response }}</span></td>
    <td>{{ showing.activityType }}</td>
    <td>{{ showing.action }}</td>
  </tr>
</template>

<script>
  export default {
    props: ['showing'],
    computed: {
      item() {
        return this.$store.state.items[this.showing.item];
      },
    },
  };
</script>

<style lang="scss" scoped>
  td.history {
    text-align: left;
  }

  .didnt-know {
    color: var(--didnt-know-text-color);
  }
  .just-right {
    color: var(--just-right-text-color);
  }
  .hard {
    color: var(--hard-text-color);
  }
  .easy {
    color: var(--easy-text-color);
  }
</style>
