<template>
  <ActionButton v-if="!processing" @click="onCancel" :block="true" kind="danger">Cancel Session</ActionButton>
</template>

<script>
  import { CANCEL_SESSION } from '../../constants';
  import ActionButton from '../ActionButton.vue';

  export default {
    components: { ActionButton },
    data() {
      return {
        processing: false,
      };
    },
    methods: {
      onCancel() {
        this.$emit('canceling');
        this.processing = true;
        this.$store.dispatch(CANCEL_SESSION).then(() => {
          this.$gtag.event('cancel_session');
          this.$mixpanel.track('CancelSession');
          this.processing = false;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
