<script>
  import { Bar, mixins } from 'vue-chartjs';

  export default {
    props: ['options'],
    extends: Bar,
    mixins: [mixins.reactiveProp],
    mounted() {
      const options = {
        ...this.options,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: value => (value % 1 === 0 ? value : null),
            },
            gridLines: {
              display: false,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
        },
        responsive: true,
        maintainAspectRatio: false,
      };
      this.renderChart(this.chartData, options);
    },
  };
</script>
