<template>
  <span class="rating-button" :class="kind" @click.prevent="$emit('responded', kind)"><slot /></span>
</template>

<script>
  export default {
    props: {
      kind: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  span.rating-button:hover {
    color: var(--rating-button-hover-text-color);
  }

  span.first-response-yes-wrong,
  span.first-response-maybe-no,
  span.first-response-no-hard,
  span.didnt-know {
    color: var(--didnt-know-text-color);
    &:hover {
      background: var(--didnt-know-background-color);
    }
  }
  span.first-response-yes-know-it,
  span.got-it,
  span.just-right {
    color: var(--just-right-text-color);
    &:hover {
      background: var(--just-right-background-color);
    }
  }
  span.first-response-maybe-yes,
  span.first-response-no-okay,
  span.first-response-yes-barely-know-it,
  span.still-struggling,
  span.hard {
    color: var(--hard-text-color);
    &:hover {
      background: var(--hard-background-color);
    }
  }
  span.first-response-yes-wont-forget,
  span.easy {
    color: var(--easy-text-color);
    &:hover {
      background: var(--easy-background-color);
    }
  }
</style>
