/* eslint-disable import/prefer-default-export */
export const FIRST_RESPONSE_YES_WRONG = 'first-response-yes-wrong';
export const FIRST_RESPONSE_YES_KNOW_IT = 'first-response-yes-know-it';
export const FIRST_RESPONSE_YES_BARELY_KNOW_IT = 'first-response-yes-barely-know-it';
export const FIRST_RESPONSE_YES_WONT_FORGET = 'first-response-yes-wont-forget';
export const FIRST_RESPONSE_MAYBE_YES = 'first-response-maybe-yes';
export const FIRST_RESPONSE_MAYBE_NO = 'first-response-maybe-no';
export const FIRST_RESPONSE_NO_HARD = 'first-response-no-hard';
export const FIRST_RESPONSE_NO_OKAY = 'first-response-no-okay';
export const RESPONSE_DIDNT_KNOW = 'didnt-know';
export const RESPONSE_JUST_RIGHT = 'just-right';
export const RESPONSE_HARD = 'hard';
export const RESPONSE_EASY = 'easy';
export const RESPONSE_STILL_STRUGGLING = 'still-struggling';
export const RESPONSE_GOT_IT = 'got-it';

export const AUTH_LOGIN = 'authLogin';
export const AUTH_LOGOUT = 'authLogout';
export const AUTH_PASSWORD_RESET_CONFIRM = 'authConfirmPasswordReset';

export const TOGGLE_ADMIN_MODE = 'toggleAdminMode';

export const FIRST_RESPONSE_DONT_KNOW = [
  FIRST_RESPONSE_YES_WRONG,
  FIRST_RESPONSE_NO_OKAY,
  FIRST_RESPONSE_NO_HARD,
  FIRST_RESPONSE_MAYBE_NO,
];
export const FIRST_RESPONSE_ALREADY_KNOWN = [
  FIRST_RESPONSE_YES_KNOW_IT,
  FIRST_RESPONSE_YES_BARELY_KNOW_IT,
  FIRST_RESPONSE_YES_WONT_FORGET,
  FIRST_RESPONSE_MAYBE_YES,
];
export const RESPONSE_PUT_BACK = [
  ...FIRST_RESPONSE_DONT_KNOW,
  RESPONSE_DIDNT_KNOW,
  RESPONSE_STILL_STRUGGLING,
];

export const START_SESSION = 'startSession';
export const SELECT_SESSION = 'selectSession';
export const SELECT_ITEM = 'selectItem';
export const SET_DAY = 'setDay';
export const SELECT_USER = 'selectUser';

export const SET_LANGUAGE = 'setLanguage';
export const SETTING_LANGUAGE = 'settingLanguage';

export const RECORD_RESPONSE = 'recordResponse';

export const ACTION_PUT_BACK = 'put-back';
export const ACTION_DONE = 'done';
export const ACTION_TEST_OUT = 'test-out';

export const FETCH_PROFILE = 'fetchProfile';
export const FETCH_ITEMS = 'fetchItems';
export const FETCH_USER_ITEMS = 'fetchUserItems';
export const FETCH_SESSIONS = 'fetchSessions';

export const END_SESSION = 'endSession';
export const CANCEL_SESSION = 'cancelSession';

export const NEW_SEEN_DAILY_MAX = 10;
