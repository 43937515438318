import algos from './algos';

/**
 * Calculates a histogram of items seen
 *
 * @param {Object} itemShowings A hash indexed by item id that has a showings list
 */
const seenCounts = itemShowings => {
  const itemCounts = Object.keys(itemShowings).reduce((map, itemId) => ({
    ...map,
    [itemId]: itemShowings[itemId].length,
  }), {});
  const countMap = Object.keys(itemCounts).reduce((map, itemId) => {
    const count = itemCounts[itemId];
    if (count === 0) {
      return map;
    }
    return {
      ...map,
      [count]: (map[count] || 0) + 1,
    };
  }, {});
  return countMap;
};

/**
 * Calculates a histogram of streaks
 *
 * @param {Object} itemShowings A hash indexed by item ids of showings Arrays
 */
const streakCounts = (itemShowings) => Object.keys(itemShowings).map(itemId => {
  const sessionShowingMap = itemShowings[itemId].reduce((map, showing) => {
    if (map[showing.session]) {
      map[showing.session].push(showing);
    } else {
      map[showing.session] = [showing];
    }
    return map;
  }, {});

  const showings = Object.keys(sessionShowingMap)
    .map(id => sessionShowingMap[id][0])
    .filter(s => s !== undefined);

  const ratings = showings.reduce((arr, obj) => [
    ...arr,
    obj.response,
  ], []);

  const streak = ratings.reduce((s, rating) => algos.calculateStreak(rating, s), 0);

  return [streak, itemId];
}).reduce((map, streakItem) => {
  const streak = streakItem[0];
  if (map[streak] === undefined) {
    map[streak] = 0;
  }
  return {
    ...map,
    [streak]: map[streak] + 1,
  };
}, {});

/**
 * Calculates a histogram of due dates
 *
 * @param {Array} items Items that have a nextDue set
 * @param {Number} day The current day to consider for dueness
 */
const dueCounts = (items, day) => items.reduce((map, item) => {
  const due = item.nextDue - day;
  return {
    ...map,
    [due]: (map[due] || 0) + 1,
  };
}, {});

/**
 * Calculates a histogram of strengths
 *
 * @param {Array} items Merged Items and UserItems
 */
const strengthCounts = (items) => items.reduce((map, item) => {
  const { strength } = item;
  let c;
  if (strength === 0) {
    c = 'strength-unknown';
  }
  if (strength > 0 && strength < 1) {
    c = 'strength-very-weak';
  }
  if (strength >= 1 && strength < 2) {
    c = 'strength-weak';
  }
  if (strength >= 2 && strength < 3) {
    c = 'strength-just-ok';
  }
  if (strength >= 3 && strength < 4) {
    c = 'strength-good-enough';
  }
  if (strength >= 4 && strength < 5) {
    c = 'strength-good';
  }
  if (strength >= 5 && strength < 6) {
    c = 'strength-strong';
  }
  if (strength >= 6) {
    c = 'strength-very-strong';
  }
  if (strength === null || strength === undefined) {
    c = 'strength-unseen';
  }
  return {
    ...map,
    [c]: map[c] + 1,
  };
}, {
  'strength-unknown': 0,
  'strength-very-weak': 0,
  'strength-weak': 0,
  'strength-just-ok': 0,
  'strength-good-enough': 0,
  'strength-good': 0,
  'strength-strong': 0,
  'strength-very-strong': 0,
  'strength-unseen': 0,
});

export default {
  seenCounts,
  streakCounts,
  dueCounts,
  strengthCounts,
};
