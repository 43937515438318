import {
  FIRST_RESPONSE_YES_WRONG,
  FIRST_RESPONSE_YES_KNOW_IT,
  FIRST_RESPONSE_YES_BARELY_KNOW_IT,
  FIRST_RESPONSE_YES_WONT_FORGET,
  FIRST_RESPONSE_NO_HARD,
  FIRST_RESPONSE_NO_OKAY,
  RESPONSE_DIDNT_KNOW,
  FIRST_RESPONSE_MAYBE_YES,
  FIRST_RESPONSE_MAYBE_NO,
  RESPONSE_EASY,
  RESPONSE_JUST_RIGHT,
  RESPONSE_HARD,
  RESPONSE_GOT_IT,
  RESPONSE_STILL_STRUGGLING,
  RESPONSE_PUT_BACK,
} from './constants';

const SESSION_LIMIT_COUNT = 10;

const STRENGTHS = {
  [RESPONSE_STILL_STRUGGLING]: () => 0,
  [RESPONSE_GOT_IT]: () => 0,
  [FIRST_RESPONSE_NO_HARD]: () => 0,
  [FIRST_RESPONSE_NO_OKAY]: () => 1,
  [FIRST_RESPONSE_MAYBE_NO]: () => 0,
  [FIRST_RESPONSE_MAYBE_YES]: () => 2,
  [FIRST_RESPONSE_YES_WRONG]: () => 0,
  [FIRST_RESPONSE_YES_BARELY_KNOW_IT]: () => 2,
  [FIRST_RESPONSE_YES_KNOW_IT]: () => 3,
  [FIRST_RESPONSE_YES_WONT_FORGET]: () => 5,
  [RESPONSE_DIDNT_KNOW]: () => 0,
  [RESPONSE_HARD]: strength => strength + 0.3,
  [RESPONSE_JUST_RIGHT]: strength => strength + 0.5,
  [RESPONSE_EASY]: strength => strength + 0.7,
};

const calcStrength = (response, strength) => STRENGTHS[response](strength);
const nextDue = (when, nextDueCounts, strength) => {
  const n1 = when + Math.round(Math.exp(strength - 0.5));
  const n2 = when + Math.round(Math.exp(strength + 0.5));

  const span = n2 - n1 + 1;
  const holder = new Array(span).fill(null);

  const days = holder.map((_, i) => n1 + i);
  const counts = holder.map((_, i) => nextDueCounts[n1 + i] || 0);
  const smallest = Math.min(...counts);
  const indexes = counts.reduce((arr, count, index) => (count === smallest ? [...arr, index] : arr), []);
  const index = Math.floor(Math.random() * indexes.length);

  return days[indexes[index]];
};

const NO_STREAK = [
  RESPONSE_GOT_IT,
  RESPONSE_STILL_STRUGGLING,
  RESPONSE_PUT_BACK,
];
const DIDNT_KNOW_STREAK = [
  RESPONSE_DIDNT_KNOW,
  FIRST_RESPONSE_MAYBE_NO,
  FIRST_RESPONSE_NO_HARD,
];

const calculateStreak = (response, currentStreak) => {
  if (NO_STREAK.indexOf(response) > -1) {
    return currentStreak;
  }

  if (DIDNT_KNOW_STREAK.indexOf(response) > -1) {
    if (currentStreak >= 0) {
      return -1;
    }
    return currentStreak - 1;
  }
  if (currentStreak <= 0) {
    return 1;
  }
  return currentStreak + 1;
};

const itemsForSession = (overdueItems, dueItems, neverSeenItems) => {
  const pickList = [...overdueItems, ...dueItems, ...neverSeenItems];
  return pickList.slice(0, SESSION_LIMIT_COUNT).map(item => item.id);
};

export default {
  nextDue,
  calcStrength,
  itemsForSession,
  calculateStreak,
};
