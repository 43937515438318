<template>
  <Card class="card metrics-card" :class="kind" :title="title" v-if="hasNotCompletedASession">
    <p>Charts will appear here once you start learning.</p>
  </Card>
  <Card class="card metrics-card" :class="kind" :title="title" v-else>
    <div class="toggle" v-if="kind === 'both'">
      <a :class="{ active: showProgress === false }" href @click.prevent="showProgress = false">Counts</a>
      <a :class="{ active: showProgress === true }" href @click.prevent="showProgress = true">Progress</a>
    </div>
    <div v-if="showProgress || kind === 'progress'">
      <LearningTree class="tree-root" :tree="tree" />
    </div>
    <div v-else-if="!showProgress || kind === 'counts'">
      <div class="chart-wrapper">
        <div class="label">Times Seen</div>
        <Histogram :chart-data="seenItemCountsData" :height="200" />
      </div>
      <div class="chart-wrapper">
        <div class="label">Strength</div>
        <Histogram :chart-data="strengthCountsData" :height="200" />
      </div>
      <div class="chart-wrapper">
        <div class="label">Due Date</div>
        <Histogram :chart-data="dueDateCountsData" :height="200" />
      </div>
    </div>
  </Card>
</template>

<script>
  import Card from './Card.vue';
  import Histogram from './Histogram.vue';
  import LearningTree from './LearningTree.vue';

  const generateRange = (min, max) => {
    const a = [];
    for (let i = min; i <= max; i += 1) {
      a.push(i);
    }
    return a;
  };

  export default {
    props: {
      kind: {
        type: String,
        default: 'both',
      },
    },
    components: {
      Card,
      Histogram,
      LearningTree,
    },
    data() {
      return {
        showProgress: false,
      };
    },
    computed: {
      hasNotCompletedASession() {
        return !this.$store.state.hasCompletedSessions;
      },
      tree() {
        return this.$store.state.learningTree;
      },
      title() {
        return {
          both: 'Metrics',
          progress: 'Progress',
          counts: 'Counts',
        }[this.kind];
      },
      seenItemCountsData() {
        const { seenItemCounts } = this.$store.getters;
        return {
          labels: Object.keys(seenItemCounts),
          datasets: [
            {
              label: 'Seen Counts',
              backgroundColor: context => {
                const x = Object.keys(seenItemCounts)[context.dataIndex];
                return `rgba(150, 200, 250, ${Math.min(0.1 + (x / 10), 1)})`;
              },
              data: Object.values(seenItemCounts),
            },
          ],
        };
      },
      strengthCountsData() {
        const { countsOfStrength } = this.$store.getters;
        return {
          labels: [
            '0',
            '0-1',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6+',
          ],
          datasets: [{
            label: 'Strength Counts',
            backgroundColor: [
              '#FF4444',
              '#FFB2B4',
              '#AECCAE',
              '#8FCC8F',
              '#6FCC6F',
              '#52CC52',
              '#33CC33',
              '#00E600',
            ],
            data: [
              countsOfStrength['strength-unknown'],
              countsOfStrength['strength-very-weak'],
              countsOfStrength['strength-weak'],
              countsOfStrength['strength-just-ok'],
              countsOfStrength['strength-good-enough'],
              countsOfStrength['strength-good'],
              countsOfStrength['strength-strong'],
              countsOfStrength['strength-very-strong'],
            ],
          }],
        };
      },
      dueDateCountsData() {
        const { countsByDueDates } = this.$store.getters;
        const min = Math.min(...Object.keys(countsByDueDates));
        const max = Math.max(...Object.keys(countsByDueDates));
        const range = generateRange(min, max);
        const data = Object.freeze(range.map(x => {
          const y = countsByDueDates[x] !== undefined
            ? countsByDueDates[x]
            : 0;
          return { x, y };
        }));
        const labels = data.map(d => d.x);
        return {
          labels,
          datasets: [{
            label: 'Due Date Counts',
            backgroundColor: context => {
              const x = labels[context.dataIndex];
              let c;
              if (x < 0) {
                c = '#F44';
              } else if (x === 0) {
                c = '#FD4';
              } else if (x > 0) {
                const a = 0.5 + Math.max((100 - x) / 200, 0);
                c = `rgba(102, 204, 102, ${a})`;
              }
              return c;
            },
            data,
          }],
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .toggle {
    display: flex;
    justify-content: center;

    a {
      display: inline-block;
      padding: 0.5rem 1rem;
      opacity: 0.7;
      text-decoration: none;
      &.active {
        opacity: 1;
      }
    }
    a,
    a:hover,
    a:link,
    a:visited {
      color: var(--body-text-color);
    }
  }
  .chart-wrapper {
    margin: 2rem 0;
    .label {
      margin-bottom: 0.5rem;
      text-align: center;
    }
  }
  .card.progress {
    padding-right: 1.5rem;
    .tree-root {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
  }
</style>
